import ImgIconX from "../../../assets/images/icons/x.svg";
import styled from "styled-components";
import {Colors, WebFonts} from "../../../styles/GlobalStyle";
import useGameStore from "../../../stores/gameStore";
import {Trans} from "react-i18next";

const HistoryWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;

const HistoryModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 320px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    gap: 10px;
    h2 {
        font-size: 18px;
        font-family: ${WebFonts.SamsungSharpSans};
    }
    .__close {
        margin-left: auto;
    }
`;

const ModalBody = styled.div`
    border-top: 1px solid #d1d1d1;
    .__history-item {
        &+.__history-item {
            border-top: 1px dashed #e1e1e1;
        }
        padding: 14px;
        h3 {
            font-size: 14px;
        }
        .__status {
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            strong {
                color: ${Colors.Purple};
                font-weight: 700;
            }
            small {
                opacity: .6;
                font-size: 12px;
            }
        }
    }
    .__no-data {
        text-align: center;
        padding: 20px;
        font-size: 14px;
    }
`;
export default function History({closeHandler}) {

    const luckydrawHistory = useGameStore(state => state.luckydrawHistory);
    return (
        <HistoryWrapper>
            <HistoryModal>
                <ModalHeader>
                    <h2><Trans i18nKey="luckydraw:my_entry_history">My entry history</Trans> </h2>
                    <button className="__close" onClick={closeHandler}><img src={ImgIconX} alt="close"/></button>
                </ModalHeader>
                <ModalBody>
                    {luckydrawHistory && luckydrawHistory.length > 0 && luckydrawHistory.map((history, index) => (
                            <div key={`history-item-${index}`} className="__history-item">
                                <h3><Trans i18nKey={`home:lucky_draw_prize${history.prize}`}/></h3>
                                <div className="__status">
                                    <strong>-1 <Trans i18nKey="luckydraw:stamp">stamp</Trans></strong>
                                    <small>{history.created_at}</small>
                                </div>
                            </div>
                        ))}

                    {(!luckydrawHistory || luckydrawHistory.length === 0) && (
                        <p className="__no-data">No Data</p>
                    )}

                </ModalBody>
            </HistoryModal>
        </HistoryWrapper>
    )
}