import styled from "styled-components";
import {BorderRadius, Colors, Gutters, WebFonts} from "./GlobalStyle";
import ImgQ from "../assets/images/quiz/Q.png";
import IconAnswerChecked from "../assets/images/icons/answer-checked.svg";
import IconAnswerX from "../assets/images/icons/answer-x.svg";
import IconAnswerO from "../assets/images/icons/answer-o.svg";
export const GameStep2PageWrapper = styled.div`
    align-self: flex-start;
    background: #F4F4F4;
`

export const QuestionBox = styled.div`
    margin: ${Gutters.default};
    border: 1px solid rgba(0,0,0,.15);
    background: #fff url(${ImgQ}) 50% 50% no-repeat;
    background-size: auto 174px;
    
    border-radius: ${BorderRadius.xl};
    min-height: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: ${Gutters.md};
    h2 {
        // font-family: ${WebFonts.SamsungSharpSans};
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        strong {
            color: ${Colors.Purple};
            text-decoration: underline;
        }
        span {
            display: block;
            opacity: .5;
            font-size: 15px;
        }
        small {
            font-weight: 500;
            font-size: 15px;
            display: block;
            margin-top: .8em;
            line-height: 18px;
            span {
                display: inline-block;
                text-indent: -999em;
                overflow: hidden;
                width: 62px;
                height: 18px;
                background: #FFFFFF;
                border: 1px solid #8834DC;
                border-radius: 4px;
                vertical-align: top;
                margin: -2px 0 0 0;
            }
        }
    }
`;

export const QuizNotice = styled.div`
    padding: 0 ${Gutters.default};
    margin: 26px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A0A0A0;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    max-width: 270px;
    .line {
        display: block;
        flex: 1;
        border-top: 1px dashed #A0A0A0;
        margin-left: 10px;
    }
    
    i {
        display: block;
        width: 50px;
        position: relative;
        img {
            display: block;
            position: absolute;
            width: 102px;
            height: auto;
            bottom: -28px;
            left: -16px;
        }
    }
    
`;


export const AnswerOptions = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 ${Gutters.default};
    gap: 10px;
    padding-bottom: ${Gutters.md};
`;

export const AnswerOption = styled.button`
    display: flex;
    align-items: center;
    padding: ${Gutters.xs};
    box-sizing: border-box;
    
    background: #fff;
    border-radius: 30px;
    min-height: 54px;
    font-size: 14px;
    font-weight: 700;
    gap: 5px;
    position: relative;
    text-align: left;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 30px;
        content: '';
        box-sizing: border-box;
    }
    span {
        display: block;
        padding: ${Gutters.xs};
    }
    i {
        display: block;
        margin-left: auto;    
        background: url(${IconAnswerChecked}) 50% 50% no-repeat;
        background-size: contain;
        width: 34px;
        min-width: 34px;
        height: 34px;
        border-radius: 50%;
        color: #939393;
        overflow: hidden;
        isolation: isolate;
    }
    
    &.active {
        &:after {
            border: 2px solid ${Colors.Blue};
        }
        i {
            background: ${Colors.Blue} url(${IconAnswerO}) 50% 50% no-repeat;
            color: #fff;
            
        }
        &[data-type="x"] {
            &:after {
                border: 2px solid ${Colors.Red};
            }
            i {
                background: ${Colors.Red} url(${IconAnswerX}) 50% 50% no-repeat;

            }
        }
    }
`;