import styled from "styled-components";
import Img_2nd_o from "../../../assets/images/game/spot/2nd/o.jpg";
import Img_2nd_x from "../../../assets/images/game/spot/2nd/x.jpg";
import Img_4th_o from "../../../assets/images/game/spot/4th/o.jpg";
import Img_4th_x from "../../../assets/images/game/spot/4th/x.jpg";
import ImgO from "../../../assets/images/game/spot/o.png";
import ImgChecked from '../../../assets/images/game/spot/checked.svg';
import {useEffect, useState} from "react";
import useGameStore from "../../../stores/gameStore";
import {GAME_PERIOD_2ND, GAME_PERIOD_4TH} from "../../../states/gameState";

const IMAGES = [];
IMAGES[GAME_PERIOD_2ND] = [Img_2nd_o, Img_2nd_x];
IMAGES[GAME_PERIOD_4TH] = [Img_4th_o, Img_4th_x];

const GameWrapper = styled.div`
`;

const SpotImages = styled.div`
    display: grid;
    gap: 6px;
    justify-content: center;
    padding: 90px 8px;
    margin: 0 auto;
    max-width: 360px;
`;

const SpotImage = styled.div`
    position: relative;
    background: linear-gradient(180deg, #B0C7EB -14.87%, #104AA3 100%);
    border: 1px solid #6B89B6;
    border-radius: 8px;
    padding: 8px;
`;

const ImgWrapper = styled.div`
    position: relative;
    .img {
        display:block;
        width: 100%;
        height: auto;
        pointer-events: none;
    }
    &.orig {
        button {
            //opacity: 0;
        }
    }
`;

const ButtonO = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    //background: rgba(200,200,200,.5);
    &:after {
        display: block;
        content: '';
        background: url(${ImgO}) no-repeat center center;
        background-size: contain;
        width: ${56 * .6}px;
        height: ${58 * .6}px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
        transform-origin: 50% 50%;
        pointer-events: none;
    }
    &[data-status="active"] {
        pointer-events: none;
        &:after {
            opacity: 1;
            transition: opacity 400ms, transform 400ms;
            transform: translate(-50%, -50%) scale(1);
        }
    }
`;

const GameStatus = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,.5);
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: clamp(6px, 3vw, 14px);
`;

const Status = styled.div`
    background: #E6E6E6;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: block;
    box-sizing: border-box;
    &[data-status="active"] {
        border-color: #8834DC;
        background: #8834DC url(${ImgChecked}) no-repeat center center;
    }
`;

const SPOT_DATA = []

SPOT_DATA[GAME_PERIOD_2ND] = [
    {x: 20, y: 28, status: ''},
    {x: 23, y: 72, status: ''},
    {x: 76, y: 17, status: ''},
    {x: 54, y: 28, status: ''},
]
SPOT_DATA[GAME_PERIOD_4TH] = [
    {x: 24, y: 55, status: ''},
    {x: 30, y: 68, status: ''},
    {x: 50, y: -2, status: ''},
    {x: 49, y: 12, status: ''},
    {x: 86, y: 83, status: ''},
]
export default function SpotTheDifferencePlay({setIsCompleted}) {

    const gameInfo = useGameStore(state => state.gameInfo);
    const [spotData, setSpotData] = useState([...SPOT_DATA[gameInfo.gamePeriod]]);
    const getActiveCount = () => {
        return spotData.filter(spot => spot.status === 'active').length;
    }

    const spotClicked = (spot) => {
        if (spot.status === '') {
            spot.status = 'active';
            setSpotData([...spotData])
        }
    }

    useEffect(() => {
        if (getActiveCount() === spotData.length) {
            setTimeout(()=>{
                setIsCompleted(true);
            }, 500);
        }
    }, [spotData]);

    useEffect(() => {
        setSpotData([...SPOT_DATA[gameInfo.gamePeriod]]);
    }, []);
    return (
        <GameWrapper>
            <SpotImages>
                <SpotImage>
                    <ImgWrapper className="orig">
                        <img src={IMAGES[gameInfo.gamePeriod][0]} className="img"/>
                        <>
                            {spotData.map((spot, i) => (
                                <ButtonO key={`orig-${i}`} onClick={e => spotClicked(spot)} data-status={spot.status} style={{left:`${spot.x}%`, top:`${spot.y}%`}}/>
                            ))}
                        </>
                    </ImgWrapper>
                </SpotImage>
                <SpotImage>
                    <ImgWrapper className="diff">
                        <img src={IMAGES[gameInfo.gamePeriod][1]} className="img"/>
                        <>
                            {spotData.map((spot, i) => (
                                <ButtonO key={`diff-${i}`} onClick={e => spotClicked(spot)} data-status={spot.status} style={{left:`${spot.x}%`, top:`${spot.y}%`}}/>
                            ))}
                        </>
                    </ImgWrapper>
                </SpotImage>
            </SpotImages>
            <GameStatus>
                {Array.from({length: spotData.length}).map((_, i) => (
                    <Status key={`status-${i}`} data-status={i < getActiveCount() ? 'active' : ''}/>
                ))}
            </GameStatus>
        </GameWrapper>
    )
}