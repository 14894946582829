import {Trans} from "react-i18next";
import {SubPageBody, SubPageHeader, SubPageWrapper} from "../styles/SubPage";
import {useNavigate} from "react-router-dom";
import winnersData from '../data/winners.json';
import {useLayoutEffect} from "react";
export default function WinnersPage() {


    const prize1 = winnersData.prize1;
    const prize2 = winnersData.prize2;
    const prize3 = winnersData.prize3;
    const prize4 = winnersData.prize4;

    const navigate = useNavigate();
    const backHandler = () => {
        navigate(-1);
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <SubPageWrapper>
            <SubPageHeader>
                <button className="x" onClick={backHandler}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="black" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <h1><Trans i18nKey={"luckydraw:winners_result_title"}>Winners Announcement</Trans></h1>
            </SubPageHeader>
            <SubPageBody>
                <p><Trans i18nKey={'luckydraw:winners_result_msg'}>Congratulations! Thank you for joining the event.
                    The admin will contact you for prize delivery</Trans></p>
                <div className="__result-block">
                    <h3>Samsung Galaxy S24+ 5G 256GB </h3>
                    <ul>
                        {prize1.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <div className="__result-block">
                    <h3>Samsung Galaxy Tab S9 Wi-Fi (8GB/128GB)</h3>
                    <ul>
                        {prize2.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <div className="__result-block">
                    <h3>"UrBox" eVoucher (200K) </h3>
                    <ul className={'__2'}>
                        {prize3.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
                <div className="__result-block">
                    <h3>"UrBox" eVoucher (100K) </h3>
                    <ul className={'__2'}>
                        {prize4.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </SubPageBody>
        </SubPageWrapper>
    )
}