import useGameStore from "../../stores/gameStore";
import {useEffect, useState} from "react";
import ImgPen from "../../assets/images/quiz/pen.png";
import {AnswerOption, AnswerOptions, GameStep2PageWrapper, QuestionBox, QuizNotice} from "../../styles/Step2";
import MessageModal from "../../components/modal/MessageModal";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {getQuizAnswer, getStampIdFromGamePeriod} from "../../states/gameState";
import {insertLuckydrawMission} from "../../api/game";
import useSSOStore from "../../stores/ssoStore";


let interval = null;
export default function Step2Page() {
    const navigate = useNavigate();
    const [isIncorrectModalOpen, setIsIncorrectModalOpen] = useState(false);
    const [isCorrectModalOpen, setIsCorrectModalOpen] = useState(false);
    const gameStep = useGameStore(state => state.gameStep);
    const setGameStep = useGameStore(state => state.setGameStep);
    const gameInfo = useGameStore(state => state.gameInfo);
    const user = useSSOStore(state => state.user);
    const {t} = useTranslation();
    const correctAnswer = getQuizAnswer(gameInfo.gamePeriod);
    const ANSWERS = [
        {"answer": t(`quiz-${gameInfo.gamePeriod}:answer1`), "correct": correctAnswer === 1},
        {"answer": t(`quiz-${gameInfo.gamePeriod}:answer2`), "correct": correctAnswer === 2},
        {"answer": t(`quiz-${gameInfo.gamePeriod}:answer3`), "correct": correctAnswer === 3},
        {"answer": t(`quiz-${gameInfo.gamePeriod}:answer4`), "correct": correctAnswer === 4}
    ];
    const [answers, setAnswers] = useState(ANSWERS);
    useEffect(() => {
        setGameStep(2);
    }, []);

    const checkAnswer = (answer) => {
        const newAnswers = answers.map((item) => {
            if (item.answer === answer.answer) {
                return {...item, active: true}
            } else {
                return {...item, active: false}
            }
        })
        setAnswers([...newAnswers]);
        hideIncorrectModal();
        hideCorrectModal();
        if (interval) clearTimeout(interval);
        if (answer.correct) {
            interval = setTimeout(showCorrectModal, 250);
        } else {
            interval = setTimeout(showIncorrectModal, 250);
        }
    }

    const showIncorrectModal = () => {
        setIsIncorrectModalOpen(true);
    }
    const hideIncorrectModal = () => {
        setIsIncorrectModalOpen(false);
    }

    const showCorrectModal = () => {
        setIsCorrectModalOpen(true);
    }

    const hideCorrectModal = () => {
        setIsCorrectModalOpen(false);
    }

    const complete = () => {
        navigate('/game/step3');
        insertLuckydrawMission(user, getStampIdFromGamePeriod(gameInfo.gamePeriod), 2).then((result) => {
            console.log(result);
        });
    }

    return (
        <GameStep2PageWrapper>
            <QuestionBox>
                <h2 dangerouslySetInnerHTML={{__html:t(`quiz-${gameInfo.gamePeriod}:question`)}}></h2>
            </QuestionBox>
            <QuizNotice>
                <span><Trans i18nKey="game:please_choose_correct_answer">Please choose the correct answer.</Trans> </span>
                <span className="line"></span>
                <i><img src={ImgPen} alt="Pen"/></i>
            </QuizNotice>

            <AnswerOptions>
                {answers.map((answer, index) => (
                    <AnswerOption key={index} className={answer.active ? 'active': ''} data-type={answer.correct ? 'o': 'x'} onClick={e=> checkAnswer(answer)}><span>{answer.answer}</span><i></i></AnswerOption>
                ))}
            </AnswerOptions>

            {isIncorrectModalOpen && <MessageModal msg={`<strong>${t('game:please_try_again')}</strong>`} btns={[{label:t('game:try_again'), action:hideIncorrectModal}]} isX={true} />}


            {isCorrectModalOpen && <MessageModal msg={`<strong>${t('game:correct')}</strong>`} btns={[{label:t('game:move_to_game'), size:'lg', action:complete}]} isO={true} />}
        </GameStep2PageWrapper>
    )
}