import styled from "styled-components";
import {Colors, Gutters, WebFonts} from "./GlobalStyle";
import {StyledButton} from "./StyledButton";
export const GameStep1PageWrapper = styled.div`
    align-self: stretch;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const QuizMain = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;


export const QuizTitle = styled.h2`
    font-family: ${WebFonts.SamsungSharpSans};
    font-size: 20px;
    font-weight: 700;
    padding: 0 ${Gutters.sm};
    margin-bottom: 14px;
`;


export const QuizAction = styled.div`
    margin-top: 20px;
    padding: ${Gutters.md} ${Gutters.sm};
    p {
        font-size: 12px;
        color: #A0A0A0;
        margin-bottom: 10px;
        .remain {
            color: #0D5FDE;
        }
    }
    button {
        width: 100%;
    }
    
    opacity: 0;
    pointer-events: none;
    &[data-inited="yes"] {
        opacity: 1;
        pointer-events: initial;
    }
`;

export const QuizButton = styled(StyledButton)`
    background: ${Colors.Gray3};
    position: relative;
    overflow: hidden;
    isolation: isolate;
    .bar {
        display: block;
        background: ${Colors.Purple};
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
    }
    span {
        position: relative;
    }
    

    
`;

export const TestButton = styled.button`
    position: fixed;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    cursor: pointer;
    text-indent: -999em;
    overflow: hidden;
    background: #000;
    opacity: 0;
`;
