import axios from 'axios';
import {API_BASE_URL} from "./config";

export function setUserAgree($user, controller) {
    const config = {
        access_token: $user.accessToken
    };
    if (controller) {
        config.signal = controller.signal;
    }
    return axios.post(`${API_BASE_URL}/user/agree`, config).then(res => {
        if (res && res.data && res.data.user) return res.data.user;
        return null;
    }).catch(err => console.error(err.message));
}