import ImgPan from "../../../assets/images/game/puzzle/pan.svg";
import Img_1st_P1 from "../../../assets/images/game/puzzle/1st/p1.png";
import Img_1st_P2 from "../../../assets/images/game/puzzle/1st/p2.png";
import Img_1st_P3 from "../../../assets/images/game/puzzle/1st/p3.png";
import Img_1st_P4 from "../../../assets/images/game/puzzle/1st/p4.png";
import Img_1st_P5 from "../../../assets/images/game/puzzle/1st/p5.png";
import Img_1st_P6 from "../../../assets/images/game/puzzle/1st/p6.png";
import Img_1st_P7 from "../../../assets/images/game/puzzle/1st/p7.png";
import Img_1st_P8 from "../../../assets/images/game/puzzle/1st/p8.png";
import Img_1st_P9 from "../../../assets/images/game/puzzle/1st/p9.png";
import Img_1st_P10 from "../../../assets/images/game/puzzle/1st/p10.png";
import Img_1st_P11 from "../../../assets/images/game/puzzle/1st/p11.png";
import Img_1st_P12 from "../../../assets/images/game/puzzle/1st/p12.png";
import Img_1st_P13 from "../../../assets/images/game/puzzle/1st/p13.png";
import Img_1st_P14 from "../../../assets/images/game/puzzle/1st/p14.png";
import Img_1st_P15 from "../../../assets/images/game/puzzle/1st/p15.png";
import Img_3rd_P1 from "../../../assets/images/game/puzzle/3rd/p1.png";
import Img_3rd_P2 from "../../../assets/images/game/puzzle/3rd/p2.png";
import Img_3rd_P3 from "../../../assets/images/game/puzzle/3rd/p3.png";
import Img_3rd_P4 from "../../../assets/images/game/puzzle/3rd/p4.png";
import Img_3rd_P5 from "../../../assets/images/game/puzzle/3rd/p5.png";
import Img_3rd_P6 from "../../../assets/images/game/puzzle/3rd/p6.png";
import Img_3rd_P7 from "../../../assets/images/game/puzzle/3rd/p7.png";
import Img_3rd_P8 from "../../../assets/images/game/puzzle/3rd/p8.png";
import Img_3rd_P9 from "../../../assets/images/game/puzzle/3rd/p9.png";
import Img_3rd_P10 from "../../../assets/images/game/puzzle/3rd/p10.png";
import Img_3rd_P11 from "../../../assets/images/game/puzzle/3rd/p11.png";
import Img_3rd_P12 from "../../../assets/images/game/puzzle/3rd/p12.png";
import Img_3rd_P13 from "../../../assets/images/game/puzzle/3rd/p13.png";
import Img_3rd_P14 from "../../../assets/images/game/puzzle/3rd/p14.png";
import Img_3rd_P15 from "../../../assets/images/game/puzzle/3rd/p15.png";


import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import gsap from "gsap";
import useGameStore from "../../../stores/gameStore";
import {GAME_PERIOD_1ST, GAME_PERIOD_3RD} from "../../../states/gameState";


const IMAGES = [];
IMAGES[GAME_PERIOD_1ST] = [Img_1st_P1, Img_1st_P2, Img_1st_P3, Img_1st_P4, Img_1st_P5, Img_1st_P6, Img_1st_P7, Img_1st_P8, Img_1st_P9, Img_1st_P10, Img_1st_P11, Img_1st_P12, Img_1st_P13, Img_1st_P14, Img_1st_P15];
IMAGES[GAME_PERIOD_3RD] = [Img_3rd_P1, Img_3rd_P2, Img_3rd_P3, Img_3rd_P4, Img_3rd_P5, Img_3rd_P6, Img_3rd_P7, Img_3rd_P8, Img_3rd_P9, Img_3rd_P10, Img_3rd_P11, Img_3rd_P12, Img_3rd_P13, Img_3rd_P14, Img_3rd_P15];

const AREA_WIDTH = 270;
const AREA_HEIGHT = 416;
const SCALE = 1.25;
const PuzzleWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

`;

const PuzzleAreaWrapper = styled.div`
    padding: 80px 0;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PuzzleArea = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: ${AREA_WIDTH * SCALE}px;
    width: ${AREA_WIDTH * SCALE}px;
    
    .pan {
        display: block;
        width: 100%;
    }
`;

const DropArea = styled.div`
    width: ${218/AREA_WIDTH*100}%;
    height: ${364/AREA_HEIGHT*100}%;
    position: absolute;
    left: ${26/AREA_WIDTH*100}%;
    top: ${26.5/AREA_HEIGHT*100}%;
    img {
        width: ${110/218*100}%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-out;
        pointer-events: none;
        -webkit-user-drag: none;
        &.active {
            opacity: 1;
        }
        &[data-index="1"] {
            top: -5.5%;
            left: -8.8%;
        }
        &[data-index="2"] {
            top: -5.4%;
            left: 24.8%;
        }
        &[data-index="3"] {
            top: -3.2%;
            left: 58.4%;
        }

        &[data-index="4"] {
            top: 14.7%;
            left: -5.3%;
        }
        &[data-index="5"] {
            top: 12.5%;
            left: 24.7%;
        }
        &[data-index="6"] {
            top: 17.1%;
            left: 54.7%;
        }

        &[data-index="7"] {
            top: 37.2%;
            left: -8.9%;
        }
        &[data-index="8"] {
            top: 32.7%;
            left: 24.8%;
        }
        &[data-index="9"] {
            top: 35.0%;
            left: 58.2%;
        }

        &[data-index="10"] {
            top: 55.1%;
            left: -5.2%;
        }
        &[data-index="11"] {
            top: 52.9%;
            left: 28.4%;
        }
        &[data-index="12"] {
            top: 54.9%;
            left: 58.3%;
        }


        &[data-index="13"] {
            top: 72.6%;
            left: -5.1%;
        }
        &[data-index="14"] {
            top: 72.6%;
            left: 28.1%;
        }
        &[data-index="15"] {
            top: 74.8%;
            left: 58.3%;
        }
        
    }
`;

const PuzzleItemArea = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 120px;
    background: rgba(255, 255, 255, .4);
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    
    z-index: 3;
    overflow: hidden;

    &.dragging {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 200ms, visibility 200ms;
    }

    img {
        display: block;
        position: absolute;
        width: 110px;

        &.dragging {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }

    }
`;

const PIECE_SIZE = 112;
export default function PuzzleGamePlay({setIsCompleted}) {
    const puzzleRef = useRef(null);
    const puzzleItemAreaRef = useRef(null);
    const dropAreaRef = useRef(null);
    const [puzzleDropedCount, setPuzzleDropedCount] = useState(0);
    const gameInfo = useGameStore(state => state.gameInfo);

    let draggingItem = null;
    useEffect(() => {

        const onTouchStart = (e) => {
            e.preventDefault();
            const item = e.target;
            const index = item.dataset.index;
            const rect = item.getBoundingClientRect();
            console.log(rect)
            draggingItem = item.cloneNode(true);
            draggingItem.origin = item;
            document.querySelector('body').appendChild(draggingItem);
            item.classList.add('dragging');
            const x = e.changedTouches[0].clientX;
            const y = e.changedTouches[0].clientY;
            gsap.set(draggingItem, {
                position: 'fixed',
                x: x - PIECE_SIZE/2,
                y: y - PIECE_SIZE/2,
                left:0,
                top:0,
                zIndex: 99,
                width: 110 * SCALE,
            })
            puzzleItemAreaRef.current.classList.add('dragging');
            window.addEventListener('touchmove', onTouchMove);
            window.addEventListener('touchend', onTouchEnd);
        }

        const onTouchMove = (e) => {
            e.preventDefault();
            const x = e.changedTouches[0].clientX;
            const y = e.changedTouches[0].clientY;
            gsap.set(draggingItem, {
                x: x - PIECE_SIZE/2,
                y: y - PIECE_SIZE/2,
            });

        }

        const onTouchEnd = (e) => {
            e.preventDefault();
            window.removeEventListener('touchmove', onTouchMove);
            window.removeEventListener('touchend', onTouchEnd);
            const index = draggingItem.dataset.index;
            const rect = draggingItem.getBoundingClientRect();
            const targetItem = dropAreaRef.current.querySelector(`img[data-index="${index}"]`);
            const targetItemRect = targetItem.getBoundingClientRect();
            puzzleItemAreaRef.current.classList.remove('dragging');
            if (rect.x + rect.width / 2 > targetItemRect.left && rect.x + rect.width / 2 < targetItemRect.right && rect.y + rect.height/2 > targetItemRect.top && rect.y + rect.height/2 < targetItemRect.bottom) {
                setPuzzleDropedCount(prev=>prev + 1);
                gsap.to(draggingItem, {
                    duration:.15,
                    x: targetItemRect.x,
                    y: targetItemRect.y,
                    onComplete: () => {
                        document.querySelector('body').removeChild(draggingItem);
                        dropAreaRef.current.querySelector(`img[data-index="${index}"]`).classList.add('active');
                    }
                });
            } else {
                const originRect = draggingItem.origin.getBoundingClientRect();
                gsap.to(draggingItem, {
                    duration:.3,
                    x: originRect.x,
                    y: originRect.y,
                    onComplete: () => {
                        document.querySelector('body').removeChild(draggingItem);
                        draggingItem.origin.classList.remove('dragging')
                    }
                });
            }

        }
        // puzzleItemAreaRef안의 image들을 랜덤하게 소팅하기

        puzzleItemAreaRef.current.querySelectorAll('img').forEach((item, index) => {
            const posX =  index * (window.innerWidth - PIECE_SIZE) / 14;
            gsap.set(item, {
                left: posX,
                top: puzzleItemAreaRef.current.clientHeight / 2 - PIECE_SIZE / 2
            });
            item.addEventListener('touchstart', onTouchStart);

        });

        const items = Array.from(puzzleItemAreaRef.current.querySelectorAll('img'));
        items.sort(() => Math.random() - 0.5);
        items.forEach((item) => {
            puzzleItemAreaRef.current.appendChild(item);
        });

        return () => {
            if (puzzleItemAreaRef.current) {
                puzzleItemAreaRef.current.querySelectorAll('img').forEach((item) => {
                    item.removeEventListener('touchstart', onTouchStart);
                });
            }
            window.removeEventListener('touchmove', onTouchMove);
            window.removeEventListener('touchend', onTouchEnd);
        }
    }, []);

    useEffect(() => {
        if (puzzleDropedCount === 15) {
            puzzleItemAreaRef.current.classList.add('dragging');
            setIsCompleted(true);
        }
    }, [puzzleDropedCount]);
    return (
        <PuzzleWrapper ref={puzzleRef}>
            <PuzzleAreaWrapper>
                <PuzzleArea className="puzzle-area">
                <img src={ImgPan} className="pan"/>
                <DropArea ref={dropAreaRef}>
                    {IMAGES[gameInfo.gamePeriod].map((img, index) => (
                        <img key={`puzzle-img-${index}`} src={img} data-index={index+1}/>
                    ))}
                </DropArea>
            </PuzzleArea>
            </PuzzleAreaWrapper>
            <PuzzleItemArea ref={puzzleItemAreaRef}>
                {IMAGES[gameInfo.gamePeriod].map((img, index) => (
                    <img key={`puzzle-drag-img-${index}`} src={img} data-index={index+1}/>
                ))}
            </PuzzleItemArea>
        </PuzzleWrapper>
    )
}