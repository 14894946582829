import {create} from 'zustand';

const useHomeStore = create((set) => ({
    currentSection : 'stampMission',
    setCurrentSection: (section) => {set({currentSection: section});},

}));

export default useHomeStore;

const params = new URLSearchParams(window.location.search);
const debugValue = params.get('__debug__');

export const __IS_TEST_SERVER__ =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.game3.samsungplus.net' ||
    ( debugValue && debugValue === 'test');