import styled from "styled-components";
import {Colors, Gutters, WebFonts} from "./GlobalStyle";
import ImgX from "../assets/images/icons/x-w.svg";
export const GameWrapper = styled.div`
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const GameMain = styled.div`
    padding: 80px ${Gutters.md} 40px;
    width: 100%;
    box-sizing: border-box;
    color: #5F5F5F;

    .game-desc {
        width: 100%;
        text-align: center;
        margin: 0 auto 60px;
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto 20px;
        }
        h1 {
            font-family: ${WebFonts.SamsungSharpSans};
            color: ${Colors.Purple};
            font-size: 30px;
            font-weight: 700;
        }
        
        p {
            font-size: 12px;
            max-width: 180px;
            margin: 10px auto 0;
            line-height: 1.4;
        }
    }
    .game-action {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .btn-start {
            position: relative;
            z-index: 99;
        }
        .btn-howto {
            text-decoration: underline;
            font-weight: 700;
            font-size: 14px;
        }
    }
    
    .how-to {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;

        .how-to-inner {
            padding: 20px ${Gutters.md} 80px;
            box-sizing: border-box;

            h2 {
                font-family: ${WebFonts.SamsungSharpSans};
                font-size: 20px;
                text-align: center;
                margin-bottom: 24px;
            }

            img {
                display: block;
                margin: 0 auto 24px;
            }

            p {
                text-align: center;
                max-width: 254px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                margin: 0 auto;
            }
        }
        .btn-close {
            position: absolute;
            width: 22px;
            height: 22px;
            background: url(${ImgX}) no-repeat center center;
            background-size: contain;
            display: block;
            text-indent: -999em;
            overflow: hidden;
            right: 20px;
            top: 29px;
        }
    }
    
`;

export const CongratWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 440px;
    }
`;