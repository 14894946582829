import styled from "styled-components";
import {Colors, Gutters} from "../../styles/GlobalStyle";
import {Trans} from "react-i18next";

const TotalStampsWrapper = styled.div`
    margin-top: 30px;
    background: #fff;
    border-radius: 30px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    gap: 1em;
    padding: ${Gutters.xs} ${Gutters.md};
    .remains {
        display: flex;
        align-items: center;
        gap: .5em;
        strong {
            font-size: 30px;
            color: ${Colors.Purple};
        }
    }
`;

export default function TotalStamps({remains}) {
    return (
        <TotalStampsWrapper>
            <h3><Trans i18nKey="luckydraw:total_stamp">Total stamps earned</Trans></h3>
            <div className="remains"><strong>{remains}</strong> <Trans i18nKey="luckydraw:stamp">Stamp</Trans></div>
        </TotalStampsWrapper>
    )
}