import styled from "styled-components";
import {BorderRadius, Colors, Gutters, Layout, WebFonts} from "../styles/GlobalStyle";
import IconChecked from "../assets/images/icons/checked.svg";
import {StyledButton} from "../styles/StyledButton";
import {useState} from "react";
import PrivacyPolicyModal from "../components/modal/PrivacyPolicyModal";
import {useNavigate} from "react-router-dom";
import TermsAndConditionsModal from "../components/modal/TermsAndConditionsModal";
import { useTranslation } from 'react-i18next';
import useSSOStore from "../stores/ssoStore";
import {setUserAgree} from "../api/user";


const IntroPageWrapper = styled.section`
    word-break: keep-all;
    min-height: 100vh;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
`;

const Agreement = styled.div`
    padding: ${Gutters.md};
    padding-bottom: 110px;
    h1 {
        font-family: ${WebFonts.SamsungSharpSans};
        font-size: 20px;
        margin-bottom: 36px;
        font-weight: 700;
        strong {
            color: ${Colors.Purple};
            font-weight: 700;
        }
    }
    p {
        font-size: 14px;
        max-width: 234px;
        margin-bottom: clamp(50px, 12vh, 80px);
    }

`;

const AgreeCta = styled.div`
    position: fixed;
    padding: ${Gutters.md};
    bottom: ${Gutters.xs};
    left: 0;
    right: 0;
    z-index: 9;
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
    button {
        width: 100%;
    }
`;

const AgreeBoxGroup = styled.div`
    display: grid;
    gap: 10px;
`;
const AgreeBox = styled.label`
    display: inline-flex;
    gap: 7px;
    position: relative;
    padding: 2px 0;
    font-size: 14px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
    }
    input[type="checkbox"] {
        margin-top: -2px;
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #D4D4D4;
        background-color: ${Colors.Gray1};
        pointer-events: none;
        &:checked {
            background: ${Colors.Blue} url(${IconChecked}) 50% 50% no-repeat;
        }
    }
    
    span {
        display: block;
        i {
            color: ${Colors.Blue};
            font-style: normal;
        }
    }
`;

const AgreeBoxAll = styled(AgreeBox)`
    margin-bottom: 10px;
    position: relative;
    > * {
        position: relative;
    }
    &:before {
        content: '';
        position: absolute;
        top: -${BorderRadius.md};
        left: -${BorderRadius.md};
        right: -${BorderRadius.md};
        bottom: -${BorderRadius.md};
        border-radius: ${BorderRadius.md};
        background-color: #F5F6F8;
    } 
`;
export default function AgreementPage() {

    const user = useSSOStore(state => state.user);
    const [agreeAll, setAgreeAll] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    function agree1Handler(e) {
        setAgree1(e.target.checked);
    }
    function agree2Handler(e) {
        setAgree2(e.target.checked);
    }
    function back1Handler(agree = false) {
        setShowModal1(false);
        setAgree1(agree);
    }

    function back2Handler(agree = false) {
        setShowModal2(false);
        setAgree2(agree);
    }

    const navigate = useNavigate();
    const agreeContinueHandler = (e) => {
        if (!agree1 || !agree2) return;
        setUserAgree(user);
        navigate("/home");
    }

    const { t } = useTranslation();

    return (
        <IntroPageWrapper>
            <Agreement>
                <h1>
                    <strong>Lucky Draw</strong><br/>
                    Samsung+<br/>
                    Weekly Stamp Mission
                </h1>
                <p>{t('intro:msg_agreement')}</p>
                <AgreeBoxGroup>
                    <AgreeBoxAll onClick={e => {
                        if (agree2 === false) setShowModal2(true);
                        if (agree1 === false) setShowModal1(true);
                        setAgreeAll(true);
                    }}>
                        <input type="checkbox" disabled checked={agree1 && agree2}/>
                        <span>{t('intro:agree_all')}</span>
                    </AgreeBoxAll>
                    <AgreeBox onClick={e => {
                        setShowModal1(true);
                        setAgreeAll(false);
                    }}>
                        <input type="checkbox" onChange={agree1Handler} disabled checked={agree1}/>
                        <span>{t('intro:agree_option1')} <i>({t('intro:agree_required')})</i></span>
                    </AgreeBox>
                    <AgreeBox onClick={e => {
                        setShowModal2(true);
                        setAgreeAll(false);
                    }}>
                        <input type="checkbox" onChange={agree2Handler} disabled checked={agree2}/>
                        <span>{t('intro:agree_option2')} <i>({t('intro:agree_required')})</i></span>
                    </AgreeBox>
                </AgreeBoxGroup>
                <AgreeCta>
                    <StyledButton disabled={!agree1 || !agree2} onClick={agreeContinueHandler}>{t('intro:continue')}</StyledButton>
                </AgreeCta>
            </Agreement>
            {showModal2 && <PrivacyPolicyModal backHandler={back2Handler}/>}
            {showModal1 && <TermsAndConditionsModal backHandler={back1Handler}/>}
        </IntroPageWrapper>
    )
}