import GlobalStyle from "./styles/GlobalStyle";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import AgreementPage from "./pages/AgreementPage";
import HomePage from "./pages/HomePage";
import SSOCallback from "./pages/SSOCallback";
import GamePage from "./pages/GamePage";
import SplashPage from "./pages/SplashPage";
import PrivateRoute from "./PrivateRoute";
import LuckyDrawPage from "./pages/LuckyDrawPage";
import PuzzleGame from "./pages/game/PuzzleGame";
import MasterPage from "./pages/MasterPage";
import WinnersPage from "./pages/WinnersPage";

function App() {

    return (
        <div className="App">
            <GlobalStyle/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SplashPage/>}/>
                    <Route path="/master" element={<MasterPage/>}/>
                    <Route path="/test" element={<LuckyDrawPage/>}/>
                    <Route path="/agreement" element={
                        <PrivateRoute>
                            <AgreementPage />
                        </PrivateRoute>
                    } />
                    <Route path="/home" element={
                        <PrivateRoute>
                            <HomePage />
                        </PrivateRoute>
                    } />
                    <Route path="/game/*" element={
                        <PrivateRoute>
                            <GamePage />
                        </PrivateRoute>
                    } />
                    <Route path="/luckydraw" element={
                        <PrivateRoute>
                            <LuckyDrawPage />
                        </PrivateRoute>
                    } />
                    <Route path="/winners" element={
                        <PrivateRoute>
                            <WinnersPage />
                        </PrivateRoute>
                    } />
                    <Route path="/sso-callback" element={<SSOCallback/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
