import axios from 'axios';
import {API_BASE_URL} from "./config";

export function getLuckydrawStampInfo($user, controller) {
    const config = {
        params: {
            access_token: $user.accessToken,
            event: $user.event,
        }
    };

    if (controller) {
        config.signal = controller.signal;
    }
    return axios.get(`${API_BASE_URL}/game/getLuckydrawStampInfo`, config).then(res => {
        if (res && res.data) return res.data;
        return null;
    }).catch(err => console.error(err.message));
}


export function insertLuckydrawStamp($user, $stamp) {
    const config = {
        access_token: $user.accessToken,
        event: $user.event,
        stamp: $stamp
    }
    return axios.post(`${API_BASE_URL}/game/insertLuckydrawStamp`, config).then(res => {
        if (res && res.data) return res.data;
        return null;
    }).catch(err => console.error(err.message));
}

export function insertLuckydrawMission($user, $stamp, $mission) {
    const config = {
        access_token: $user.accessToken,
        event: $user.event,
        stamp: $stamp,
        mission: $mission
    }
    return axios.post(`${API_BASE_URL}/game/insertLuckydrawMission`, config).then(res => {
        if (res && res.data) return res.data;
        return null;
    }).catch(err => console.error(err.message));
}