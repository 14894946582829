import {Gutters, Layout, WebFonts} from "./GlobalStyle";
import styled from "styled-components";

export const HomeContainer = styled.div`
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
    box-sizing: border-box;
    width: 100%;
`;
export const HomeSectionTitle = styled.h2`
    font-family: ${WebFonts.SamsungSharpSans};
    font-size: 20px;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;
    padding: ${Gutters.sm} 0;
`;

