import styled from "styled-components";
import {Colors} from "../styles/GlobalStyle";
import Footer from "../components/common/Footer";
import HomeHeader from "../components/home/Header";
import HomeLuckyDraw from "../components/home/LuckyDraw";
import HomeStampMission from "../components/home/StampMission";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import HomeHowToEnter from "../components/home/HowToEnter";
import {useEffect, useRef} from "react";
import useSSOStore from "../stores/ssoStore";
import {getLuckydrawStampInfo} from "../api/game";
import useGameStore from "../stores/gameStore";


gsap.registerPlugin(ScrollTrigger);
const HomeWrapper = styled.div`
    background: ${Colors.Gray1};
    min-height: 100vh;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const HomeSections = styled.div`
    overflow: hidden;
`;


export default function HomePage() {
    const hasFetchedRef = useRef(false);
    const user = useSSOStore(state => state.user);
    const setGameInfo = useGameStore(state => state.setGameInfo);
    useEffect( () => {
        if (hasFetchedRef.current) return;
        hasFetchedRef.current = true;
        const controller = new AbortController()
        getLuckydrawStampInfo(user, controller).then((info) => {
            hasFetchedRef.current = false;
            if (!info) return;
            // info.game_period = '4th';
            if (sessionStorage.getItem('gamePeriod') && sessionStorage.getItem('gamePeriod') !== '') {
                info.game_period = sessionStorage.getItem('gamePeriod');
            }
            const gameInfo = {
                gamePeriod: info.game_period,
                stampCount: info.stamp_count,
                stampMissionLog: info.stamp_mission_log,
                stampUserData: info.stamp_userdata,
            };
            setGameInfo(gameInfo);
        });
        return () => {
            // controller.abort();
        }
    }, [getLuckydrawStampInfo]);
    return (
        <HomeWrapper>
                <HomeHeader/>
                <HomeSections>
                    <HomeStampMission />
                    <HomeLuckyDraw />
                    <HomeHowToEnter />
                </HomeSections>
            <Footer/>
        </HomeWrapper>
    )
}