import ImgTitleObj from "../../assets/images/luckydraw/title-obj.png";
import styled from "styled-components";
import {Colors, WebFonts} from "../../styles/GlobalStyle";
import ssoStore from "../../stores/ssoStore";
import {Trans} from "react-i18next";

const LuckyDrawHeaderWrapper = styled.header``;

const LuckyDrawDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    span {
        font-family: ${WebFonts.SamsungSharpSans};
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        background: linear-gradient(169.09deg, #9452FF 3.46%, #6967F9 96.31%);
        padding: .3em 1.6em .1725em;
        border-radius: 2em;
        display: block;
    }
`;

const LuckyDrawTitle = styled.h1`
    font-family: ${WebFonts.SamsungSharpSans};
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: ${Colors.Purple};
    position: relative;
    line-height: 1;
    img {
        position: absolute;
        display: block;
        width: 252px;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -9px;
    }
`;

export default function LuckyDrawHeader() {
    const user = ssoStore(state => state.user);
    console.log("user", user);
    return (
        <LuckyDrawHeaderWrapper>
            <LuckyDrawTitle>
                <img src={ImgTitleObj}/>
                <strong><Trans i18nKey="luckydraw:title">Lucky<br/>Draw</Trans></strong>
            </LuckyDrawTitle>
            <LuckyDrawDate><span>2024. 7. 8 - 7. 14</span></LuckyDrawDate>
        </LuckyDrawHeaderWrapper>
    )
}