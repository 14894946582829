import TermsModal, {TermContent} from "./TermsModal";
import {useTranslation} from "react-i18next";

export default function TermsAndConditionsModal({backHandler}) {
    const { t } = useTranslation();
    return (
        <TermsModal backHandler={backHandler} title={t('terms-and-conditions:title')}>
            <TermContent>
                <p>By entering this 2024 Weekly Stamp Event (“Event”) held by Samsung Electronics Co., Ltd (“Samsung”),
                    you ("Participant") agree to be bound by these terms and conditions ("Terms"). Any information or
                    instructions included in the Event Participation Guide on the Samsung Plus app also form part of
                    these terms and conditions. The information you provide will only be used for the purposes of
                    operating the Event.</p>
                <p>Before you enter into this Event, please read these Terms carefully. IF YOU DO NOT AGREE TO THESE
                    TERMS, YOU SHALL NOT ENTER INTO THIS EVENT. You also represent that (i) you are over the age of 18
                    or greater than 14 years accompanied by your legal guardian and (ii) you or your legal guardian
                    agree to these Terms.</p>

                <h3>A. Event Period</h3>
                <p>The Event period is from 17 June, 2024 (10:00 Local Time) until 14 July, 2024 (23:59:59 Local Time)
                    ("Event Period"), unless changed by Samsung at its sole discretion.</p>
                <h3>B. Eligibility</h3>
                <ol>
                    <li>Participants can enter the Event on the Samsung+ app.</li>
                    <li>Only sales persons who are participating in the Samsung Global Retail Training Program are
                        eligible
                        to enter this Event.
                    </li>
                    <li>Each Samsung+ log-in ID is eligible for one chance to participate in each weekly Stamp Mission.
                        (i.e. Play once a week per ID).
                    </li>
                    <li>A new Stamp Mission opens at 0:00 every Monday and runs until 23:59:59 the following Sunday. At
                        0:00
                        on Monday the previous weekly mission will be closed and the new weekly Stamp Mission will
                        start.
                    </li>
                    <li>When a Weekly Stamp Mission is successfully completed, the participant earns one lucky draw
                        entry
                        per stamp earned.
                    </li>
                    <li>Participants can use multiple stamps to enter the same prize draw.</li>
                    <li>Participants are able to earn one stamp per week. After earning your stamp, you must wait until
                        the
                        next week to participate again.
                    </li>
                    <li>The lucky draw will open for entries simultaneously with the start of the 4th weekly stamp
                        mission,
                        and remain open until Sunday 23:59:59 of that week. A maximum of 4 stamps (i.e. 1 per week) can
                        be
                        earned over the duration of the Event.
                    </li>
                    <li>Winners will be drawn separately for each prize.</li>
                    <li>The chance of winning a particular prize is based on the number of participants who entered into
                        that specific draw. (e.g. If 1,500 people entered the Smartphone draw, the odds of winning would
                        be
                        100/1,500*100= 6.67%)
                    </li>
                    <li>The draw is probability based, so one person could win multiple prizes.</li>
                    <li>Samsung does not take any responsibility for an individual’s non-participation due to opting out
                        from receiving notifications.
                    </li>
                    <li>If a participant leaves the event page before completing the current mission, the mission will
                        restart from the beginning.
                    </li>
                    <li>Stamps are redeemed as a condition of entry into the lucky draw, regardless of the outcome of
                        the
                        draw.
                    </li>
                    <li>If your Samsung+ account is deactivated, all remaining stamps will be removed from your
                        record.
                    </li>
                    <li>Event participation may be restricted in certain cases, subject to the service operation policy.
                    </li>
                    <li>No purchase is necessary to enter this Event.</li>
                </ol>


                <h3>C. The Prize</h3>
                <ol>
                    <li>Prizes can only be won by earning stamps and redeeming them to enter the lucky draw during the
                        period of the Event
                    </li>
                    <li>Participants can go to ‘My entry history’ for a record of their lucky draw entries.</li>
                    <li>A winner will no longer be eligible to receive a prize if that person resigns from their
                        position
                        before the winners are announced.
                    </li>
                    <li>Winning prizes are non-transferable.</li>
                    <li>Prizes that have been shipped are not eligible for exchange, return, or refund.</li>
                    <li>Actual prizes can vary subject to the country in which the Participant takes part in the
                        Event.
                    </li>
                    <li>Prizes pictured in the Event Participation Guide are for illustrative purposes only. Any details
                        and other restrictions regarding prizes that are not specified in these Terms will be determined
                        by
                        Samsung at
                        its sole discretion. The type, number, or color of any prize may be subject to change without
                        notice, and
                        will be chosen randomly by Samsung.
                    </li>
                    <li>All prizes will be delivered by the admin at the end of the Stamp Mission event.</li>
                    <li>Prizes will be awarded and provided on an "AS IS" basis with no warranty or guarantee
                        whatsoever,
                        either express or implied.
                    </li>
                    <li>Nothing contained herein should be construed as an endorsement by Samsung of any prize suppliers
                        or
                        other third party, product, or service.
                    </li>
                    <li>No cash or other substitution, assignment or transfer of any prizes is permitted, except by
                        Samsung,
                        who reserves the right to substitute a prize or prize component with cash or another prize of
                        Samsung’s choice.
                    </li>
                    <li>ANY VIOLATION OF THESE TERMS BY A WINNER WILL RESULT IN DISQUALIFICATION AS AN EVENT WINNER, AND
                        ALL
                        PRIVILEGES AS A WINNER WILL BE IMMEDIATELY TERMINATED.
                    </li>
                </ol>
                <h3>D. Notification of Winners</h3>
                <ol>
                    <li>Winners can check prizes in “Find out the winners” on the Stamp Mission app.</li>
                    <li>Monthly winners will be announced on the Monday of the second week following the end of the
                        Event. (i.e. There is a 1-week delay between the end of the Event and the announcement of
                        winners.)
                    </li>
                    <li>Please note, if a winner does not claim their prize within two weeks of being contacted by the
                        admin, prize delivery may be canceled.
                    </li>
                </ol>

                <h3>E. Publicity</h3>
                <p>Except where prohibited, by participating in the Event, the Participant grants Samsung an
                    irrevocable, perpetual, royalty free right and license to use, publish, including posting online,
                    his or her name, all submitted articles, and place of residency in any media for advertising and
                    promotional purposes without additional compensation or consent, except where prohibited by law.</p>
                <h3>F. Privacy and Data Protection</h3>
                <ol>

                    <li>Other than as set out in these Terms or for the purposes of operating the Event, the information
                        provided by Participants when entering the Event or claiming a prize will not be used for any
                        purpose other than to notify winners and for prize fulfillment purposes in connection with the
                        Event, nor shall they be passed to any third party unless necessary for the aforesaid purpose.
                    </li>
                    li>
                    <li>Participants' personal information will be used for the purpose set forth herein and kept
                        strictly
                        confidential. All personal information shall be permanently destroyed after use unless otherwise
                        provided in the law.</li>
                </ol>
                <h3>G. General Conditions</h3>
                <ol>

                    <li>By entering this Event, you agree to Samsung Plus Terms and Conditions as well as these Terms
                        which
                        are final and binding in all respects related to the Event.
                    </li>
                    <li>Samsung reserves the right to alter, amend, foreclose or cancel the Event or the terms and
                        conditions of entry at its sole discretion at any time and without prior notice.
                    </li>
                    <li>Samsung reserves the right, at its sole discretion, to disqualify any Participants deemed to be
                        (a)
                        tampering or attempting to tamper with the entry process or the operation of the Event or any
                        website of Samsung or its partners; (b) violating these Terms; (c) acting with intent to annoy,
                        abuse, threaten or harass any other person; or (d) violating copyrights of any other third
                        person/entity’s intellectual properties.
                    </li>
                    <li>Any attempt by any person to deliberately damage any website or undermine the legitimate
                        operation
                        of the
                        Event is a violation of criminal and civil law, and, should such an attempt be made, Samsung
                        reserves the
                        right to seek damages from any such person to the fullest extent permitted by law. Samsung's
                        failure
                        to
                        enforce any term of these Terms shall not constitute a waiver of that provision.
                    </li>
                    <li>A participant who receives any benefits from the Event may have tax consequences. Should there
                        be
                        any tax
                        liability for participation or the receipt of a prize, or costs or expenses relating to
                        participation or for
                        any other reasons, such taxes and/or expenses are the sole responsibility of the Participant. In
                        the
                        event
                        of occurrence of withholding tax, Samsung may set a date by which such tax must be paid by the
                        winner to claim the prize and if the winner fails to make payment for the tax within the time
                        period, the winner’s prize may be forfeited.
                    </li>
                    <li>This Event is governed by the law of each Participant’s country.</li>
                    <li>Participants who are selected as one of the Event winners, are not allowed to re-sell any of the
                        prizes. By doing so, a Participant will be banned from any future Samsung Events held by or on
                        behalf of Samsung.
                    </li>
                    <li>If the Event cannot be operated as planned for any reason, including without limitation,
                        infection
                        by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failures, or any
                        other causes which corrupt or affect the administration, security, fairness, integrity, or
                        proper
                        conduct of the Event, Samsung reserves the right, at its sole discretion to cancel, delay,
                        modify,
                        or terminate the Event without any prior notice.
                    </li>
                    <li>Samsung shall not be liable for any disruption to the Event, whether due to technical problems
                        or
                        otherwise, which is beyond Samsung’s reasonable control.
                    </li>
                    <li>Any decision of Samsung related to the Event is final and Samsung is not obligated to provide
                        any
                        correspondence regarding the results. Samsung accepts no responsibility for entries that are
                        lost,
                        misdirected, illegible, late, mutilated or altered. Entries that do not comply with these Terms
                        will
                        be disqualified.
                    </li>
                    <li>By entering the Event, you agree to release Samsung from any liability whatsoever for any
                        claims,
                        costs, injuries, losses, or damages of any kind arising out of or in connection with the Event
                        or
                        with the acceptance, possession, attendance at, or use of any prize.
                    </li>
                    <li>Samsung reserves the right to amend these Terms at any time. All matters and disputes will be
                        subject to the final decision of Samsung.
                    </li>
                    <li>This event is subject to change/discontinuation at the discretion of Samsung.</li>
                </ol>


                <h3>Event Promoter</h3>
                <p>Samsung Electronics Co., Ltd, 129 Samsung Ro, Yeongtong-Gu, Suwon, Gyunggi-Do, South Korea 16677</p>

                <h3>- Contact list of this Event: : 2024 Stamp Mission event</h3>
                <p>Rino.Park (rino.park@samsung.com) at HQ Retail team<br/>
                    Hyunjoo Lee (ask.lee@samsung.com) at Cheil<br/>
                    Chaewon Yoon (chaeone.yoon@samsung.com) at Cheil<br/>
                    Jiyeon An (jiyeon.an@partner.samsung.com) at Cheil<br/>
                    Mos Baek (mos.baek@samsung.com) at Cheil</p>
            </TermContent>
        </TermsModal>
    )
}