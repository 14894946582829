import styled from "styled-components";
import ImgIconInfo from "../../assets/images/icons/info.svg";
import ImgIconX from "../../assets/images/icons/answer-x.svg";
import ImgIconO from "../../assets/images/icons/answer-o.svg";
import {Colors, WebFonts} from "../../styles/GlobalStyle";
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
`;

const ModalBox = styled.div`
    max-width: 260px;
    width: 90%;
    margin: auto;
    background: #fff;
    border-radius: 30px;
    padding: 26px 20px;
    word-break: keep-all;
    box-sizing: border-box;
`;


const ModalBody = styled.div`
    text-align: center;
    h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    p {
        line-height: 1.4;
        strong {
            font-size: 16px;
        }
        
        em {
            color: ${Colors.Red};
            font-style: normal;
        }
    }
`

const ModalIcon = styled.div`
    margin: 0 auto 10px;
    img,
    svg {
        display: block;
        margin: 0 auto;
    }
`;

const ModalFooter = styled.footer`
    display: flex;
    justify-content: space-around;

`;

const ButtonCta = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 16px;
    text-underline-offset: 3px;
    &.lg {
        font-family: ${WebFonts.SamsungSharpSans};
        text-decoration: none;
        font-size: 16px;
        background: ${Colors.Purple};
        flex: 1;
        color: #fff;
        height: 40px;
        border-radius: 40px;
    }
`;


export default function MessageModal({title, msg, btns = null,  isInfo = false, isX = false, isO = false}) {
    return (
        <ModalWrapper>
            <ModalBox>
                <ModalBody>
                    {isInfo && (
                        <ModalIcon>
                            <img src={ImgIconInfo}/>
                        </ModalIcon>
                    )}
                    {isO && (
                        <ModalIcon>
                            <img src={ImgIconO}/>
                        </ModalIcon>
                    )}
                    {isX && (
                        <ModalIcon>
                            <img src={ImgIconX}/>
                        </ModalIcon>
                    )}
                    {title && <h3>{title}</h3>}
                    {msg && <p dangerouslySetInnerHTML={{__html:msg}}></p>}
                </ModalBody>
                <ModalFooter>
                    {btns && btns.map((btn, index) => (
                        <ButtonCta key={index} className={btn?.size} onClick={btn.action}>{btn.label}</ButtonCta>
                    ))}
                </ModalFooter>
            </ModalBox>
        </ModalWrapper>
    )
}
