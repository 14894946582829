import styled from "styled-components";
import {Colors, WebFonts} from "../../styles/GlobalStyle";
import ImgStatusClosed from "../../assets/images/icons/status-closed.svg";
import ImgStatusOpen from "../../assets/images/icons/status-open.svg";
import ImgStatusCompleted from "../../assets/images/icons/status-completed.svg";
import ImgStamp from "../../assets/images/icons/stamp.svg";
import {useNavigate} from "react-router-dom";
import {useTransition} from "react";
import {useTranslation} from "react-i18next";
const IMG_STATUS = {
    closed: ImgStatusClosed,
    open: ImgStatusOpen,
    locked: ImgStatusOpen,
    completed: ImgStatusCompleted
}
const StampItemWrapper = styled.a`
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 4px;
    font-family: ${WebFonts.SamsungSharpSans};
    font-weight: 700;
    color: #D9D9D9;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #D9D9D9;
        border-radius: 15px;
    }
    
    .no {
        text-align: center;
        font-size: 14px;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.125);
        background: #FFFFFF;
        border-radius: 50%;
        position: relative;
        img,
        svg {
            display: block;
            width: 44px;
            height: 44px;
            position: relative;
            z-index: 4;
        }
        span {
            position: relative;
            z-index: 2;
        }
        &:before {
            content: '';
            border: 2px solid #EAEAEA;
            box-sizing: border-box;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            position: absolute;
        }
    }

    .date {
        font-size: 16px;
        margin-top: 6px;
        height: 1.25em;
        display: block;
    }
    .status {
        font-size: 10px;
        text-transform: capitalize;
        height: 1.25em;
        display: block;
    }
    .cta {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #D9D9D9;
        margin-top: 10px;
        svg {
            display: block;
        }
    }
    &.status-open {
        
        &:after {
            border-width: 2px;
            border-color: ${Colors.Purple};
        }
        .no {
            border: 2px solid ${Colors.Purple};
            color: #fff;
            margin: -1px;
            &:before {
                background: ${Colors.Purple};
                border: 0;
            }
        }
        .date {
            color: #3E3E3E;
        }
        .status {
            color: ${Colors.Purple};
            text-transform: uppercase;
            
        }
        .cta {
            background: ${Colors.Purple};
            opacity: .5;
        }
    }
    
    &.status-closed {
        background: #EAEAEA;
        color: #A6A6A6;
        &:after {
            border-width: 0px;
        }
        .no {
            background: #EAEAEA;
            &:before {
                border-color: #D9D9D9;
            }
        }
        .cta {
            background: #BDBDBD;
        }
    }
    
    &.completed-yes {
        background: linear-gradient(169.09deg, #9452FF 3.46%, #6967F9 96.31%);
        .date,
        .status {
            color: #fff;
        }
        .status {
            text-transform: initial;
        }
        &.status-closed {
            .date,
            .status {
                color: rgba(255,255,255,.5);
            }
        }
        &:after {
            border-width: 0px;
        }
        .no {
            border-width: 0;
            &:before {
                display: none;
            }
        }
        .cta {
            background: #fff;
            opacity: 1;
        }
    }
`;

export default function StampItem({no, date, status, completed}) {
    const navigate = useNavigate();
    function goGame() {
        if ( status === 'open') navigate('/game');
    }
    const { t } = useTranslation();

    return (
        <StampItemWrapper className={`status-${status} completed-${completed}`} onClick={goGame}>
            <span className="no">{completed === 'yes' ? <img src={ImgStamp}/> : <span>{no}</span>}</span>
            <span className="date">{date}</span>
            <span className="status">{completed === 'yes' ? t(`home:stamp_completed`) : t(`home:stamp_${status}`)}</span>
            <span className="cta"><img src={IMG_STATUS[completed === 'yes' ? 'completed' : status]}/></span>
        </StampItemWrapper>
    )
}