import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useSSOStore from "../stores/ssoStore";
import {HomeContainer} from "../styles/HomeStyle";
import styled from "styled-components";
import {getUserInfo} from "../api/sso";
import {WebFonts} from "../styles/GlobalStyle";
import i18n from "i18next";
import {getLangCodeFromId} from "../i18n/langs";


const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    dl {
        dt {
            font-weight: 900;
            font-size: 11px;
            margin-bottom: 2px;
        }
        
        dd {
            font-weight: 400;
            font-size: 14px;
        }
    }
`;

const SSOCallbackMsg = styled.div`
    min-height: 100vh;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
        font-size: 18px;
        font-weight: 700;
        font-family: ${WebFonts.SamsungSharpSans};
    }
    
    dl {
        font-family: ${WebFonts.SamsungSharpSans};
        display: none;
    }
    
`;


export default function SSOCallback() {
    const navigate = useNavigate();
    const location = useLocation();
    const setUser = useSSOStore(state => state.setUser);
    const setGrantCode = useSSOStore(state => state.setGrantCode);
    const accessToken = useSSOStore(state => state.accessToken);
    const grantCode = useSSOStore(state => state.grantCode);
    const [userInfo, setUserInfo] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchUserInfo = async (code) => {
        const user = await getUserInfo(code);
        if ( user.localeCode ) {
            const localeCodeSplit = user.localeCode.split('-');
            const langCode = localeCodeSplit[0];
            user.event = 'luckydraw_vi';
            await i18n.changeLanguage(langCode);
        }
        return user;
    };

    useEffect(() => {
        const controller = new AbortController();
        if (isFetching) {
            fetchUserInfo(grantCode, controller).then((user) => {
                setUser(user);
                setIsFetching(false);
                if (user.agree === 'yes') {
                    navigate('/home');
                    return;
                }
                navigate('/agreement');
            });
        }
        return () => {
            controller.abort();
        }
    }, [isFetching, grantCode]);

    useEffect(() => {
        const url = new URLSearchParams(location.search);
        const code = url.get('code');
        if (code && !isFetching) {
            setGrantCode(code);
            setIsFetching(true);
        }
    }, [location.search]);
    return (
        <HomeContainer>
            {userInfo === null ? (
                <SSOCallbackMsg>
                    <h1>Logging in.</h1>
                    {/*<dl>*/}
                    {/*    <dt>Access Token</dt>*/}
                    {/*    <dd><code style={{fontSize: '10px', wordBreak: 'break-all'}}>{accessToken}</code></dd>*/}
                    {/*</dl>*/}
                </SSOCallbackMsg>
            ) : (
                <UserInfo>
                    {/*{Object.entries(userInfo).map(([key, value]) => (*/}
                    {/*    <div key={key}>*/}
                    {/*        <dl>*/}
                    {/*            <dt>{key}:</dt>*/}
                    {/*            <dd>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</dd>*/}
                    {/*        </dl>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </UserInfo>
            )}
        </HomeContainer>
    )
}