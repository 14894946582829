import {useEffect, useState} from "react";
import styled from "styled-components";
import {WebFonts} from "../../styles/GlobalStyle";
import {Trans} from "react-i18next";


const TimeRemainsWrapper = styled.div`
    text-align: center;
    
    h2 {
        font-family: ${WebFonts.SamsungSharpSans};
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        gap: .2em;
        font-family: Monospace;
        span, i {
            display: block;
        }
        span {
            background: #2B4878;
            color: #fff;
            width: 2em;
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            position: relative;
            strong {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-size: .45em;
                font-weight: 900;
                background: #2B4878;
                padding: .25em .5em;
                border-radius: 50%;
            }
            
        }
        i {
            color: #2B4878;
            font-style: normal;
            font-size: 1em;
        }
    }
`;

export default function TimeRemains({endDate}) {

    const END_TIME = new Date(endDate).getTime();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);


    const getRemains = (timeRemains) => {
        const remains = Math.max(0, timeRemains);
        const days = Math.floor(remains / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remains % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remains % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remains % (1000 * 60)) / 1000);
        // console.log(days, hours, minutes, seconds);
        return {days, hours, minutes, seconds};
    }

    useEffect(() => {

        const calcRemains = () => {
            const currentTime = new Date().getTime();
            const timeRemains = END_TIME - currentTime;
            const remains = getRemains(timeRemains);
            setDays(remains.days);
            setHours(remains.hours);
            setMinutes(remains.minutes);
            setSeconds(remains.seconds);
        }
        const interval = setInterval(() => {
            calcRemains();
        }, 1000);

        calcRemains();

        return () => {
            clearInterval(interval);
        }
    }, []);

    const numPad = (num) => {
        return num < 10 ? `0${num}` : num;
    }

    return (
        <TimeRemainsWrapper>
            <h2><Trans i18nKey="luckydraw:time_remaining">Time remaining</Trans></h2>
            <div className="timer">
                { days >= 0 && <span>{numPad(days)} <strong>D</strong></span>}
                { days >= 0 && <i>:</i>}
                <span>{numPad(hours)} <strong>H</strong></span>
                <i>:</i>
                <span>{numPad(minutes)} <strong>M</strong></span>
                <i>:</i>
                <span>{numPad(seconds)} <strong>S</strong></span>
            </div>
        </TimeRemainsWrapper>
    )
}