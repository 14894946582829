
import { createGlobalStyle } from 'styled-components';
import WebFontSamsungOneKoreanOTF500 from '../assets/fonts/SamsungOneKoreanOTF-500.woff2';
import WebFontSamsungOneKoreanOTF600 from '../assets/fonts/SamsungOneKoreanOTF-600.woff2';
import WebFontSamsungOneKoreanOTF700 from '../assets/fonts/SamsungOneKoreanOTF-700.woff2';
// import WebFontSamsungSharpSansRegular from '../assets/fonts/SamsungSharpSans-Regular.woff2';
// import WebFontSamsungSharpSansMedium from '../assets/fonts/SamsungSharpSans-Medium.woff2';
// import WebFontSamsungSharpSansBold from '../assets/fonts/SamsungSharpSans-Bold.woff2';
import WebFontSamsungSharpSansRegular from '../assets/fonts/iCielSamsungSharpSansRegular.woff2';
import WebFontSamsungSharpSansMedium from '../assets/fonts/iCielSamsungSharpSans-Medium.woff2';
import WebFontSamsungSharpSansBold from '../assets/fonts/iCielSamsungSharpSans-Bold.woff2';

export const Layout = {
    maxWidth: '440px',
}


export const WebFonts = {
    SamsungOne: `'SamsungOne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    SamsungSharpSans: `'SamsungSharpSans', 'SamsungOne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
}

export const Gutters = {
    default: '15px',
    xs: '10px',
    sm: '20px',
    md: '30px',
    lg: '40px',
}

export const Colors = {
    White: '#FFFFFF',
    Black: '#000000',
    Blue: '#0D5FDE',
    Purple: '#9656FF',
    PurpleLight: '#E9DFF1',
    Gray1: '#F4F4F4',
    Gray2: '#C6C6C6',
    Gray3: '#CECECE',
    Navy: '#1B3157',
    Red: '#E74E4E',
}




export const BorderRadius = {
    sm: '5px',
    md: '10px',
    lg: '20px',
    xl: '30px',
}

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    @font-face {
        font-family: 'SamsungOne';
        src: url(${WebFontSamsungOneKoreanOTF500}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'SamsungOne';
        src: url(${WebFontSamsungOneKoreanOTF600}) format('woff2');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'SamsungOne';
        src: url(${WebFontSamsungOneKoreanOTF700}) format('woff2');
        font-weight: 700;
        font-style: normal;
    }



    @font-face {
        font-family: 'SamsungSharpSans';
        src: url(${WebFontSamsungSharpSansRegular}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'SamsungSharpSans';
        src: url(${WebFontSamsungSharpSansMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'SamsungSharpSans';
        src: url(${WebFontSamsungSharpSansBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
    }

    
    html {
        background: #fff;
    }
    
    body {
        font-family: ${WebFonts.SamsungOne};
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        text-size-adjust: 100%;
        letter-spacing: 0;
        line-height: 1.25;
    }
    
 
    a {
        text-decoration: none;
        color: inherit;
    }
    
    button,
    input {
        appearance: none;
        border: 0;
        background: none;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border-radius: 0;
        box-shadow: none;
    }
`;
export default GlobalStyle;