import {create} from 'zustand';

const useSSOStore = create((set) => ({
    grantCode: '',
    accessToken: '',
    user: {
        fullName: '',
        id: '',
        userId: '',
    },
    setGrantCode: (code) => {
        set({grantCode: code});
    },
    setAccessToken: (token) => {
        set({accessToken: token});
    },
    setUser: (user) => {
        set({user: {...user}});
    }

}));

export default useSSOStore;