import ImgMain from "../../../assets/images/game/spot/img.png";
import {StyledButton} from "../../../styles/StyledButton";
import {GameMain} from "../../../styles/Game";
import ImgHowto from "../../../assets/images/game/spot/howto.svg";
import {useState} from "react";
import {Trans} from "react-i18next";
export default function SpotTheDifferenceMain({setStartGame}) {
    const [showHowto, setShowHowto] = useState(false);
    return (
        <GameMain>
            <div className="game-desc">
                <img src={ImgMain} width={284}/>
                <h1><Trans i18nKey="game:spot_game_title">Spot the Difference</Trans></h1>
                <p><Trans i18nKey="game:spot_game_msg">Click the button below to play Spot the Difference Game!</Trans></p>
            </div>
            <div className="game-action">
                <StyledButton className="btn-start" onClick={e=>{
                    setStartGame(true);
                    window.scrollTo(0,0)
                }}><Trans i18nKey="game:start_the_game">Start the game</Trans></StyledButton>
                <button className="btn-howto" onClick={e => {
                    setShowHowto(true);
                    window.scrollTo(0,0)
                }}><Trans i18nKey="game:how_to_play">How to play</Trans></button>
            </div>

            {showHowto && (
                <div className="how-to">
                    <button className="btn-close" onClick={e=> setShowHowto(false)}>X</button>
                    <div className="how-to-inner">
                        <h2><Trans i18nKey="game:how_to_play">How to play</Trans></h2>
                        <img src={ImgHowto}/>
                        <p><Trans i18nKey="game:spot_game_how_to_msg">Mission completed when all the differences are found.</Trans></p>
                    </div>
                </div>
            )}

        </GameMain>
    )
}