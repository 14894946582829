import styled from "styled-components";
import {Colors, Gutters, WebFonts} from "../../styles/GlobalStyle";
import {HomeContainer, HomeSectionTitle} from "../../styles/HomeStyle";
import ImgP1 from "../../assets/images/home/p1.png";
import ImgP2 from "../../assets/images/home/p2.png";
import ImgP3 from "../../assets/images/home/p3.png";
import ImgP4 from "../../assets/images/home/p4.png";
import {useSectionPos} from "../../hooks/useSectionPos";
import {Trans, useTranslation} from "react-i18next";
import {StyledButton} from "../../styles/StyledButton";
import {useNavigate} from "react-router-dom";
import useGameStore from "../../stores/gameStore";

const LuckyDrawWrapper = styled.section`
    padding: 0 ${Gutters.default};
    margin-bottom: 40px;
    position: relative;
`;

const Prizes = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    row-gap: 24px;
`;

const Prize = styled.div`
    text-align: center;
    i {
        display: block;
        background: #fff;
        border-radius: 30px;
        margin-bottom: 12px;
        padding: 15px;
        box-sizing: border-box;
        img {
            width: 100%;
            box-sizing: border-box;
        }
    }
    h4 {
        font-family: ${WebFonts.SamsungSharpSans};
        color: ${Colors.Navy};
        font-size: 15px;
        font-weight: 700;
    }
    p {
        color: ${Colors.Purple};
        font-weight: 700;
        font-size: 12px;
        margin-top: 2px;
    }
`;

const Cta = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    padding: 0 ${Gutters.default};
    button {
        width: 100%;
    }
    p {
        font-size: 12px;
        opacity: .8;
    }
`;

const PrizeNotice = styled.p`
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #565656;
`;
export default function HomeLuckyDraw() {
    const {section} = useSectionPos();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const gameInfo = useGameStore(state => state.gameInfo);

    return (
        <HomeContainer>
            <LuckyDrawWrapper className="home-section" id="luckyDraw" ref={section}>
                <HomeSectionTitle><Trans i18nKey={"home:lucky_draw_title"}>Lucky Draw Prizes</Trans></HomeSectionTitle>
                <PrizeNotice>* <Trans i18nKey={"home:lucky_draw_period"} values={{period:"24. 7. 8 - 24. 7. 14"}}></Trans> *</PrizeNotice>
                <Prizes>
                    <Prize>
                        <i><img src={ImgP1} alt="Samsung Galaxy S24+ 5G 256GB"/></i>
                        <h4><Trans i18nKey={'home:lucky_draw_prize1'}/></h4>
                        <p>2 {t('home:winners')}</p>
                    </Prize>
                    <Prize>
                        <i><img src={ImgP2} alt="Samsung Galaxy Tab S9 Wi-Fi(8GB/128GB)"/></i>
                        <h4><Trans i18nKey={'home:lucky_draw_prize2'}/></h4>
                        <p>1 {t('home:winner')}</p>
                    </Prize>
                    <Prize>
                        <i><img src={ImgP3} alt="UrBox eVoucher (200K)"/></i>
                        <h4><Trans i18nKey={'home:lucky_draw_prize3'}/></h4>
                        <p>150 {t('home:winners')}</p>
                    </Prize>
                    <Prize>
                        <i><img src={ImgP4} alt="UrBox eVoucher (100K)"/></i>
                        <h4><Trans i18nKey={'home:lucky_draw_prize4'}/></h4>
                        <p>1000 {t('home:winners')}</p>
                    </Prize>
                </Prizes>
                <Cta>
                    <StyledButton onClick={()=>{
                        navigate('/luckydraw');
                    }}><Trans i18nKey="luckydraw:winners_result_cta">Find out the winners</Trans></StyledButton>
                </Cta>


            </LuckyDrawWrapper>
        </HomeContainer>
    )
}