import {HomeContainer, HomeSectionTitle} from "../../styles/HomeStyle";
import styled from "styled-components";
import Icon1 from "../../assets/images/icons/howto1.svg";
import Icon2 from "../../assets/images/icons/howto2.svg";
import Icon3 from "../../assets/images/icons/howto3.svg";
import Icon4 from "../../assets/images/icons/howto4.svg";
import {Gutters} from "../../styles/GlobalStyle";
import {useSectionPos} from "../../hooks/useSectionPos";
import {Trans} from "react-i18next";

const HowToBgWrapper = styled.div`
    background: linear-gradient(0deg, #E2E7EF 89.94%, rgba(226, 231, 239, 0) 98.68%);
    padding-top: 15px;
    padding-bottom: 25px;
`;

const HowToEnterWrapper = styled.div`
    
`;

const HowToContent = styled.div`
    padding: 0 ${Gutters.default};
`;


const HowToList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    margin: 0;
`;


const HowToListItem = styled.li`
    background: #fff;
    border-radius: 30px;
    padding: ${Gutters.xs} ${Gutters.default};
    display: flex;
    align-items: center;
    gap: 5px;
    .no {
        color: #8834DC;
        background: #EAD6FF;
        width: 22px;
        min-width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        border-radius: 50%;
    }
    i {
        display: block;
        svg {
            display: block;
        }
    }
    p {
        font-size: 14px;
        font-weight: 700;
        small {
            font-size: 12px;
            font-weight: 500;
            color: #1B3157;
        }
    }
`;


export default function HomeHowToEnter() {
    const {section} = useSectionPos();
    return (
        <HowToBgWrapper>
            <HomeContainer>
                <HowToEnterWrapper className="home-section" id="howToEnter" ref={section}>
                    <HomeSectionTitle><Trans i18nKey={"home:how_to_enter_title"}>How to enter the<br/> Stamp Mission</Trans></HomeSectionTitle>
                    <HowToContent>
                        <HowToList>
                            <HowToListItem>
                                <span className="no">1</span>
                                <i><img src={Icon1} alt=""/></i>
                                <p><Trans i18nKey={"home:how_to_1"}>Click the stamp when it unlocks each Monday.</Trans></p>
                            </HowToListItem>
                            <HowToListItem>
                                <span className="no">2</span>
                                <i><img src={Icon2} alt=""/></i>
                                <p><Trans i18nKey={"home:how_to_2"}>Watch the video then complete the quiz and game.</Trans></p>
                            </HowToListItem>
                            <HowToListItem>
                                <span className="no">3</span>
                                <i><img src={Icon3} alt=""/></i>
                                <p><Trans i18nKey={"home:how_to_3"}>Successful completion earns you 1 stamp.</Trans></p>
                            </HowToListItem>
                            <HowToListItem>
                                <span className="no">4</span>
                                <i><img src={Icon4} alt=""/></i>
                                <p><Trans i18nKey={"home:how_to_4"}>Each stamp earns you 1 chance to enter lucky draw</Trans><br/>
                                    <small><Trans i18nKey={"home:how_to_4_noti"}>(Multiple entries allowed for the same prize)</Trans></small>
                                </p>
                            </HowToListItem>
                        </HowToList>
                    </HowToContent>
                </HowToEnterWrapper>
            </HomeContainer>
        </HowToBgWrapper>
    )
}