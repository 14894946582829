import axios from 'axios';
import {API_BASE_URL} from "./config";

export function getLuckydrawInfo($user, controller) {
    const config = {
        access_token: $user.accessToken,
        event: $user.event,
    }

    if (controller) {
        config.signal = controller.signal;
    }
    return axios.post(`${API_BASE_URL}/luckydraw/getInfo`, config).then(res => {
        if (res && res.data) return res.data;
        return null;
    }).catch(err => console.error(err.message));
}



export function insertEnterToWin($user, prize) {
    const config = {
        access_token: $user.accessToken,
        event: $user.event,
        prize: prize,
    }

    return axios.post(`${API_BASE_URL}/luckydraw/enterToWin`, config).then(res => {
        if (res && res.data) return res.data;
        return null;
    }).catch(err => console.error(err.message));
}


