import ImgP1 from "../../assets/images/home/p1.png";
import ImgP2 from "../../assets/images/home/p2.png";
import ImgP3 from "../../assets/images/home/p3.png";
import ImgP4 from "../../assets/images/home/p4.png";
import styled from "styled-components";
import {Colors, WebFonts} from "../../styles/GlobalStyle";
import {Trans, useTranslation} from "react-i18next";
import useGameStore from "../../stores/gameStore";
import Count from "./Count";
import History from "./partial/History";
import {useState} from "react";
import {getLuckydrawInfo, insertEnterToWin} from "../../api/luckydraw";
import useSSOStore from "../../stores/ssoStore";
import OptionItem from "./partial/OptionItem";
import {StyledButton} from "../../styles/StyledButton";
import {useNavigate} from "react-router-dom";


const DrawerWrapper = styled.div`
    margin-top: 30px;
`;
const ButtonHistory = styled.button`
    display: block;
    margin: 0 auto 20px;
    text-decoration: underline;
    font-weight: 600;
    text-underline-offset: 3px;
`;

const Options = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    position: relative;
`;

const EndMsg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    border-radius: 16px;


    p {
        width: 80%;
        line-height: 1.4;
        color: #fff;
        font-family: ${WebFonts.SamsungSharpSans};
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
`;


export default function Drawer({endDate}) {
    const {t} = useTranslation();
    const prizeCounts = useGameStore(state => state.prizeCounts);
    const [showHistory, setShowHistory] = useState(false);
    const END_TIME = new Date(endDate).getTime();
    const currentTime = new Date().getTime();
    const timeRemains = END_TIME - currentTime;
    console.log("timeRemains", timeRemains)
    const navigate = useNavigate();
    return (
        <>
            <DrawerWrapper>
                <ButtonHistory onClick={()=>setShowHistory(true)}><Trans i18nKey="luckydraw:my_history">Check my entry history</Trans> &gt;</ButtonHistory>
                <Options>
                    {timeRemains <= 0 && (
                        <EndMsg>
                            <p>
                                <Trans i18nKey={'luckydraw:winners_announcement'}>
                                    Winners’ announcement<br/>
                                    at 1:00 PM, 22th July
                                </Trans>
                            </p>
                            <StyledButton onClick={()=>{
                                navigate('/winners');
                            }}><Trans i18nKey="luckydraw:winners_result_cta">Find out the winners</Trans></StyledButton>
                        </EndMsg>
                    )}
                    <OptionItem img={ImgP1} disable={timeRemains <= 0} prize={1} winner={(<><strong>2</strong> {t('home:winners')}</>)} count={prizeCounts.prize1}/>
                    <OptionItem img={ImgP2} disable={timeRemains <= 0} prize={2} winner={(<><strong>1</strong> {t('home:winner')}</>)} count={prizeCounts.prize2}/>
                    <OptionItem img={ImgP3} disable={timeRemains <= 0} prize={3} winner={(<><strong>150</strong> {t('home:winners')}</>)} count={prizeCounts.prize3}/>
                    <OptionItem img={ImgP4} disable={timeRemains <= 0} prize={4} winner={(<><strong>1000</strong> {t('home:winners')}</>)} count={prizeCounts.prize4}/>
                </Options>

            </DrawerWrapper>
            { showHistory && <History closeHandler={()=> setShowHistory(false)} /> }
        </>
    )
}