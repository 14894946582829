import styled from "styled-components";
import {Colors, Gutters} from "../../styles/GlobalStyle";
import IconX from "../../assets/images/icons/x.svg";
import {useNavigate} from "react-router-dom";
import useGameStore from "../../stores/gameStore";
import MessageModal from "../modal/MessageModal";
import {useEffect, useState} from "react";
import {getGameStepTitle} from "../../states/gameState";
import {Trans, useTranslation} from "react-i18next";

const GamePageHeaderWrapper = styled.div`
    padding: ${Gutters.sm};
    display: flex;
    position: relative;
    z-index: 10;
    h1 {
        font-size: 16px;
        font-weight: 700;
        span {
            display: block;
            color: ${Colors.Purple};
        }
        strong {
            display: block;
        }
    }
`;

const CloseButton = styled.button`
    margin-left: auto;
    img {
        display: block;
    }
`

const Progress = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #D9D9D9;
    span {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        background: ${Colors.Blue};
        transition: width 200ms ease-out;
        
    }
`;
export default function GamePageHeader() {
    const gameStep = useGameStore(state => state.gameStep);
    const gameInfo = useGameStore(state => state.gameInfo);
    const navigate = useNavigate();
    const [stepTitle, setStepTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    function goBack(e) {
        e.preventDefault();
        navigate('/home');
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    useEffect(() => {
        setStepTitle(getGameStepTitle(gameStep, gameInfo.gamePeriod));
    }, [gameInfo, gameStep]);
    const {t} = useTranslation();
    return (
        <GamePageHeaderWrapper>
            <Progress>
                <span style={{width:`${(parseInt(gameStep) - 1)/3 * 100}%`}}/>
            </Progress>
            <h1><span><Trans i18nKey="game:step">Step</Trans> {gameStep}</span> <strong>{stepTitle}</strong></h1>
            <CloseButton onClick={openModal}><img src={IconX} alt="close"/></CloseButton>
            {isModalOpen && <MessageModal msg={t("game:leave_msg")} btns={[
                {label:t("game:leave"), action:goBack},{label:t("game:stay"), action: closeModal}
            ]} isInfo={false} />}
        </GamePageHeaderWrapper>
    )
}