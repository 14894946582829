import ImgP1 from "../../../assets/images/home/p1.png";
import {Trans, useTranslation} from "react-i18next";
import Count from "../Count";
import styled from "styled-components";
import {Colors, WebFonts} from "../../../styles/GlobalStyle";
import {useState} from "react";
import useSSOStore from "../../../stores/ssoStore";
import useGameStore from "../../../stores/gameStore";
import {getLuckydrawInfo, insertEnterToWin} from "../../../api/luckydraw";
import MessageModal from "../../modal/MessageModal";

const OptionItemWrapper = styled.article`
    background: #fff;
    border-radius: 16px;
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    img {
        display: block;
        width: 90%;
        margin: 0 auto;
    }
    .desc {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .winner {
            font-weight: 700;
            font-size: 11px;
            color: #fff;
            background: #434343;
            border-radius: 2em;
            display: flex;
            align-items: center;
            gap: .25em;
            padding: .1em 1em;
            margin: 0 auto;
            letter-spacing: -0.05em;
            strong {
                font-size: 13px;
                font-weight: 700;
            }
        }
        h3 {
            font-family: ${WebFonts.SamsungSharpSans};
            font-weight: 700;
            text-align: center;
            font-size: 15px;
        }
        .total {
            text-align: center;
            margin-bottom: 4px;
            margin-top: auto;
            h4 {
                font-weight: 500;
                font-size: 12px;
                opacity: .5;
                line-height: 1;
            }
            .count {
                margin-top: 6px;
                display: flex;
                justify-content: center;
                gap: 2px;
                span {
                    color: #434343;
                    display: block;
                    background: #e6e6e6;
                    border: 1px solid rgba(0,0,0,.15);
                    text-align: center;
                    width: 1em;
                    padding: .1em 0 0 0;
                    font-weight: 700;
                }
            }
        }
        .cta {
            //margin-top: auto;
            button {
                width: 100%;
                display: block;
                background: ${Colors.Purple};
                color: #fff;
                font-size: 12px;
                font-family: ${WebFonts.SamsungSharpSans};
                font-weight: 700;
                padding:  .6em .5em .5em;
                border-radius: 2em;
            }
        }
    }
`;
export default function OptionItem({prize, disable, img, winner, count}) {

    const [isEnterToWin, setIsEnterToWin] = useState(false);
    const user = useSSOStore(state => state.user);
    const gameInfo = useGameStore(state => state.gameInfo);
    const setLuckydrawHistory = useGameStore(state => state.setLuckydrawHistory);
    const setPriceCounts = useGameStore(state => state.setPrizeCounts);
    const setGameInfo = useGameStore(state => state.setGameInfo);
    const enddate = useGameStore(state => state.enddate);
    const todateMs = new Date().getTime();
    const enddateMs = new Date(enddate).getTime();
    const [showMsg, setShowMsg] = useState(false);
    const {t} = useTranslation();
    const stampUserData = gameInfo.stampUserData;
    const remainStamps = calcRemainStamps(stampUserData);
    function calcRemainStamps(userData) {
        const stamp = parseInt(userData?.stamp);
        const luckydraw1 = parseInt(userData?.luckydraw1);
        const luckydraw2 = parseInt(userData?.luckydraw2);
        const luckydraw3 = parseInt(userData?.luckydraw3);
        const luckydraw4 = parseInt(userData?.luckydraw4);
        return stamp - luckydraw1 - luckydraw2 - luckydraw3 - luckydraw4;
    }
    const enterToWin = () => {
        if (isEnterToWin) return;
        setIsEnterToWin(true);

        insertEnterToWin(user, prize).then((info) => {
            setIsEnterToWin(false);
            setShowMsg(false);
            getLuckydrawInfo(user).then((info) => {
                if (info && info.counts) {
                    setPriceCounts(info.counts);
                }
                if (info && info.userdata) {
                    setGameInfo({...gameInfo, stampUserData: info.userdata});
                }
                if (info && info.history) {
                    setLuckydrawHistory(info.history);
                }
            });
        });
    }

    const closeHandler = () => {
        setShowMsg(false);
    }

    return (
       <>
           <OptionItemWrapper>
               <img src={img} />
               <div className="desc">
                   <span className="winner">{winner}</span>
                   <h3><Trans i18nKey={`home:lucky_draw_prize${prize}`}/></h3>
                   <div className="total">
                       <h4><Trans  i18nKey="luckydraw:total_entries">Total Entries</Trans></h4>
                       <Count count={count}/>
                   </div>
                   { disable || enddateMs > todateMs && (
                       <div className="cta">
                           <button onClick={() => setShowMsg(true)}><Trans i18nKey="luckydraw:enter_to_win">Enter To
                               Win</Trans></button>
                       </div>
                   )}
               </div>
           </OptionItemWrapper>
           {showMsg && remainStamps > 0 && (
               <MessageModal msg={`<strong>${t(`home:lucky_draw_prize${prize}`)}</strong><br/>${t('luckydraw:enter_to_win_msg')}`} isO={true} btns={[ {label:t('luckydraw:cancel'), action:closeHandler}, {label:t('luckydraw:enter'), action:enterToWin}]}/>
           )}
           {showMsg && remainStamps <= 0 && (
               <MessageModal isInfo={true} msg={`${t('luckydraw:no_stamp')}`} btns={[ {label:t('luckydraw:ok'), action:closeHandler}]}/>
           )}
       </>
    )
}