import {create} from 'zustand';
import {GAME_PERIOD_1ST} from "../states/gameState";

export const END_DATE = '2024-07-15 00:00:00';

const useGameStore = create((set) => ({
    gameStep: 1,
    gameInfo: {
        gamePeriod: GAME_PERIOD_1ST,
        stampCount: 0,
        stampMissionLog: null,
        stampUserData: null,
    },
    prizeCounts: {
        prize1: 0,
        prize2: 0,
        prize3: 0,
        prize4: 0,
    },

    luckydrawHistory: [],
    enddate: END_DATE,
    setGameStep: (step) => {
        set({gameStep: step});
    },
    setGameInfo: (info) => {
        set({gameInfo: info});
    },
    setPrizeCounts: (counts) => {
        set({prizeCounts: counts});
    },
    setLuckydrawHistory: (history) => {
        set({luckydrawHistory: history});
    },
    setEnddate: (date) => {
        set({enddate: date});
    }
}));

export default useGameStore;