import {CongratWrapper, GameWrapper} from "../../styles/Game";
import {useEffect, useState} from "react";
import MessageModal from "../../components/modal/MessageModal";
import {useNavigate} from "react-router-dom";
import useGameStore from "../../stores/gameStore";
import SpotTheDifferencePlay from "./spotTheDifference/Play";
import SpotTheDifferenceMain from "./spotTheDifference/Main";
import {useTranslation} from "react-i18next";
import {insertLuckydrawStamp} from "../../api/game";
import {getStampIdFromGamePeriod} from "../../states/gameState";
import useSSOStore from "../../stores/ssoStore";
import Lottie from "lottie-react";
import congratAnimData from "../../assets/images/game/congrat.json";


export default function SpotTheDifference() {
    const gameStep = useGameStore(state => state.gameStep);
    const gameInfo = useGameStore(state => state.gameInfo);
    const user = useSSOStore(state => state.user);
    const navigate = useNavigate();
    const [startGame, setStartGame] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isEnding, setIsEnding] = useState(false);
    const goHome = () => {
        navigate('/home');
    }

    const {t} = useTranslation();
    useEffect(() => {
        if (isCompleted) {
            insertLuckydrawStamp(user, getStampIdFromGamePeriod(gameInfo.gamePeriod)).then((result) => {
                console.log(result);
            });
        }
    }, [isCompleted]);

    return (
        <GameWrapper>
            {startGame ? <SpotTheDifferencePlay setIsCompleted={setIsCompleted}/> :  <SpotTheDifferenceMain setStartGame={setStartGame}/> }
            { isCompleted && (
                <CongratWrapper>
                    <div>
                        <Lottie animationData={congratAnimData} autoplay={true} loop={false} onComplete={()=> setIsEnding(true)}/>
                    </div>
                </CongratWrapper>
            )}
            {isEnding && <MessageModal msg={`<strong>${t('game:mission_complete')}</strong><br/>${t('game:new_stamp_earned')}`} isO={true} btns={[{label:t('game:home'), action:goHome}]}/>}
        </GameWrapper>
    )
}