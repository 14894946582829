export const GAME_PERIOD_1ST = '1st';
export const GAME_PERIOD_2ND = '2nd';
export const GAME_PERIOD_3RD = '3rd';
export const GAME_PERIOD_4TH = '4th';

export const getGameStepTitle = (step, gamePeriod) => {
    switch (step) {
        case 1:
            return 'E - Learning';
        case 2:
            return 'Quiz';
        case 3:
            return (gamePeriod === GAME_PERIOD_1ST || gamePeriod === GAME_PERIOD_3RD) ? 'Puzzle Game' : 'Spot the Difference';
        default:
            return '';
    }
}

export const getElearningVideoId = (gamePeriod) => {
    switch (gamePeriod) {
        case GAME_PERIOD_1ST:
            return 'zvxpJrjHZK4';
        case GAME_PERIOD_2ND:
            return 't0hAPnrAwZQ';
        case GAME_PERIOD_3RD:
            return 'XMAML5ofgYM';
        case GAME_PERIOD_4TH:
            return '5Fd19m27myY';
        default:
            return "";
    }
}

export const getQuizAnswer = (gamePeriod) => {
    switch (gamePeriod) {
        case GAME_PERIOD_1ST:
            return 1;
        case GAME_PERIOD_2ND:
            return 2;
        case GAME_PERIOD_3RD:
            return 2;
        case GAME_PERIOD_4TH:
            return 1;
        default:
            return 1;
    }
}

export const getStampIdFromGamePeriod = (gamePeriod) => {
    switch (gamePeriod) {
        case GAME_PERIOD_1ST:
            return 1;
        case GAME_PERIOD_2ND:
            return 2;
        case GAME_PERIOD_3RD:
            return 3;
        case GAME_PERIOD_4TH:
            return 4;
        default:
            return -1;
    }
}