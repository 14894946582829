import TermsModal, {TermContent} from "./TermsModal";
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicyModal({backHandler}) {
    const { t } = useTranslation();
    return (
        <TermsModal backHandler={backHandler} title={t('privacy-policy:title')}>
            <TermContent>
                <p> This policy (“Policy”) explains the treatment of any information that is provided to or collected by
                    the web sites on which this Policy is posted. In addition, the Policy also explains the information
                    which is provided to or collected by third-party companies in the course of using the Samsung Plus
                    application.</p>
                <p>Samsung Electronics Co., Ltd. (“Samsung,” “we,” “us,” “our,” “the company”) is responsible for the
                    information provided or collected on the websites on which this Policy is posted, and information
                    provided to or collected by third-party companies in the course of using the Samsung Plus
                    application.</p>
                <p>The company regards the personal information of users as important and will inform them, through this
                    Policy, of the purposes and methods of Samsung’s use of any personal information provided by users
                    and the measures taken by Samsung for the protection of this personal information.</p>
                <h3>Effective dates:</h3>
                <p>This Policy will take effect from the 22nd day of April, 2024 and, in case of modification thereof,
                    the company will request users to re-consent to the modified Policy. Participation requires users to
                    consent to modifications before being able to login to Samsung Plus.</p>
                <h3>1. Information to be collected and methods of collection</h3>
                <h4>(1) Personal information to be collected</h4>
                <p>
                    Personal information collected by us is as follows:<br/>
                    • Information provided by users<br/>
                    We may collect information that is directly provided by users.<br/>
                </p>
                <table>
                    <thead>
                    <tr>
                        <th>Title of service</th>
                        <th>Information to be collected</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Samsung Plus User Identification</td>
                        <td>User ID, Information on nationality</td>
                    </tr>
                    </tbody>
                </table>
                <p>• Information collected while users use services<br/>
                    Besides information directly provided by the users, we may collect information from users in the
                    course of their use of the services we provide.</p>
                <table>
                    <thead>
                    <tr>
                        <th>Lists</th>
                        <th>Items to be collected</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Equipment Information</td>
                        <td>Equipment identifier, operating system, hardware version, equipment set-up</td>
                    </tr>
                    <tr>
                        <td>Login Information</td>
                        <td>IP address, log data, cookies and web beacon</td>
                    </tr>
                    </tbody>
                </table>
                <h4>(2) Methods of collection</h4>
                <p>We collect user information of in the following ways:<br/>
                    • Information provided by users (fax, phone, email, website and other collection tools)<br/>
                    • Information provided by partner companies</p>

                <h3>2. Use of collected information</h3>
                <p>
                    We collect user information for the following purposes:<br/>
                    • Mission completion and user identification<br/>
                    • To detect and deter unauthorized or fraudulent use of or abuse of our services<br/>
                    • Improvement of existing services and development of new services<br/>
                    • Notification of weekly mission details, or changes to this policy<br/>
                    • To comply with applicable laws or legal obligations<br/>
                    If the Company desires to use the information for purposes other than those expressly stated in this
                    Policy, we will first obtain consent for those purposes from users.
                </p>

                <div className="__well">
                    <h4>&lt;Lawful processing of personal information under GDPR&gt;</h4>
                    <p>
                        The processing of personal information by Samsung shall be lawful only if and to the extent that
                        at least one of the following applies:<br/>
                        • A user has given consent to the processing of his or her personal information.<br/>
                        • Processing is necessary for the performance of a contract to which a user is party or in order
                        to take steps at the request of a user prior to entering into a contract:<br/>
                        - User identification, etc.<br/>
                        - Performance of a contract in relation to providing the services required by users<br/>
                        • Processing is necessary for compliance with a legal obligation to which the Company is subject<br/>
                        - Compliance with relevant law, regulations, legal proceedings, requests by the government<br/>
                        • Processing is necessary in order to protect the vital interests of users, or other natural
                        persons<br/>
                        - Detection of, prevention of, and response to fraud, abuse, security risks, and technical<br/>
                        issues that may harm users or other natural persons<br/>
                        • Processing is necessary for the performance of a task carried out in the public interest or in
                        the exercise of official authority vested in the Company<br/>
                        • Processing is necessary for the purposes of the legitimate interests pursued by Samsung or by
                        a third party
                    </p>
                </div>
                <h3>3. Disclosure of collected information</h3>
                <p>Except for the following cases, we will not disclose personal information to a third party:<br/>
                    • disclosure of information to Samsung affiliates, partners and service providers:<br/>
                    - When the company’s affiliates, partners and service providers carry out services such as
                    winner announcement and dispute resolution for or on behalf of Samsung<br/>
                    • disclosure is required by law:<br/>
                    - When required by specific laws and regulations; or<br/>
                    - When required by investigative agencies for the detection of crimes in accordance with the
                    procedures and methods as prescribed in the laws and regulations
                </p>
                <h3>4. Cookies, Beacons and Similar Technologies</h3>
                <p>We may collect and retain collective and impersonal information through 'cookies' or 'web beacons'.
                    Cookies are very small text files sent to a user’s browser by the server that is used for the
                    operation of Samsung’s websites and these are stored in the hard-disk of a user’s computer.<br/>
                    A web beacon is a small quantity of code embedded in a website or e-mail. Web beacons allow Samsung
                    to know if a user has interacted with a certain website or the content of an email.<br/>
                    These functions are used by Samsung to evaluate the services we deliver so that we may provide a
                    better user experience and improved services.<br/>
                    The types cookies used by Samsung and the purpose of each are as follows:
                </p>
                <table>
                    <thead>
                    <tr>
                        <th>Category</th>
                        <th>Reason for using cookies and additional information</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Strictly Necessary Cookies</td>
                        <td>This type of cookie is required in order for users to access the functions of the Samsung
                            Plus website. This cookie does not collect any information which may be used for marketing,
                            nor does it retain information on the sites visited by users<br/>
                            This type of cookie:<br/>
                            ∘ Retains information on completed missions<br/>
                            ∘ Checks whether a login is made on the website<br/>
                            ∘ Checks whether users are connected with the correct services on the Samsung website<br/>
                            ∘ Connects users with specific applications or servers for the provision of services
                        </td>
                    </tr>
                    <tr>
                        <td>Performance Cookies</td>
                        <td>This type of cookie collects information on the usage patterns of users on the Samsung
                            website, such as which pages are most visited by users. This data helps the Company to
                            optimize the website so that users may navigate it more comfortably. This cookie does not
                            collect any personally identifiable user information. Any and all information collected by
                            this cookie type will be processed collectively to maintain anonymity.<br/>
                            This type of cookie is used for:<br/>
                            ∘ Web analysis: provide statistical data on how the website is used<br/>
                            ∘ Management of errors: measure and identify errors which may occur so as to aid in
                            improving the website
                        </td>
                    </tr>
                    <tr>
                        <td>Functionality Cookies</td>
                        <td>This type of cookie is used for user settings and preferences so that Samsung provides a
                            consistent user experience each time they visit the website. Any information collected by
                            this cookie does not identify or track users individually.<br/>
                            This type of cookie<br/>
                            ∘ Remembers settings such as layout, text size, basic settings, colors, and other
                            preferences and settings.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>Users have options for cookie settings: they may allow all cookies to be saved, select cookies
                    individually, or refuse all cookies. If a user rejects all cookies, it may be difficult for that
                    user to access parts of Samsung’s website and associated services.</p>
                <h3>5. Users’ rights</h3>
                <p>Under the laws of some jurisdictions, you may have the right to request details about the information
                    we collect about you and to correct inaccuracies in that information. In accordance with applicable
                    laws, we may decline to process requests that are unreasonably repetitive, require disproportionate
                    technical effort, jeopardize the privacy of others, are extremely impractical, or for which access
                    is not otherwise required by the applicable laws. If you would like to make a request to access your
                    information, please contact our customer service department at ssplus@samsung.com.</p>
                <div className="__well">
                    <h4>Users’ rights under GDPR</h4>
                    <p>Users or their legal representatives may exercise the following rights regarding the collection,
                        use
                        and sharing of personal information by Samsung:<br/>
                        • The right to access:<br/>
                        Users or their legal representatives may access the information, and check the records of the
                        collection, use and sharing of this information.<br/>
                        • The right to rectification:<br/>
                        - Users or their legal representatives may request the correction of inaccurate or incomplete
                        information.<br/>
                        • The right to erasure;<br/>
                        - Users or their legal representatives may request the deletion of their information after the
                        purpose of its collection is no longer applicable, and with the withdrawal of their
                        consent.<br/>
                        • The right to restrict processing;<br/>
                        - Users or their legal representatives may request the temporary suspension of the use of
                        personal
                        information in the case of disputes over the accuracy of information, the legality of the use of
                        information, or the necessity of retaining personal information.<br/>
                        • The right to data portability<br/>
                        - Users or their legal representatives may request the provision or transfer of their
                        information.<br/>
                        • The right to object<br/>
                        - Users or their legal representatives may object to the processing of personal information for
                        certain reasons, such as direct marketing, profiling, research and statistics.<br/>
                        • The right object to automated individual decision-making, including profiling<br/>
                        - The users or their legal representatives may object to decisions made solely through automated
                        processing, including profiling, which have legal or other significant effects on them.</p>

                    <p>In order to exercise the above rights, users may amend their information through the Samsung
                        website, contact the Company by sending a document or e-mail, or telephone the Company (call the
                        person in charge of the management of personal information or a deputy). After being contacted,
                        Samsung will take measures with minimal delay. The Company may reject a user request only to the
                        extent that there exists either proper cause as prescribed in the laws or equivalent cause.</p>
                </div>
                <h3>6. Security</h3>
                <p>Samsung regards the security of personal information of users as very important and takes the
                    following security measures to protect users' personal information from any unauthorized access,
                    release, use or modification</p>
                <p>• Encryption of personal information<br/>
                    - Transmission of users' personal information through encrypted communication<br/>
                    - Encryption of important information, such as passwords, before storage<br/>
                    • Countermeasures against hacking<br/>
                    - The installation and use of systems that control external access so as to prevent the leakage
                    or damage of users' personal information by hacking or computer virus<br/>
                    • Establish and execute internal management plans<br/>
                    • Install and operate access control systems<br/>
                    • Take measures to prevent forging or alteration of access records
                </p>
                <h3>7. Modification of Privacy Protection Policy</h3>
                <p>We have the right to amend or modify this Policy from time to time and, in such cases, we will
                    request your consent to the most recent T&C and/or privacy policy. Participation requires users to
                    consent to modifications before being able to login to Samsung Plus.</p>
                <h3>8. Data transfer to other countries</h3>
                <p>Due to Samsung’s engagement in global business, we may provide users' personal information to
                    companies located in other countries for the purposes expressly stated in this Policy. In cases
                    where personal information is transmitted, retained or processed, the Company takes reasonable
                    measures to protecting this personal information.</p>
                <p>In addition, when personal information obtained from users in the European Union is used or
                    disclosed, the Company may have to take additional measures or obtain consent from users, so as to
                    comply with the regulations of the EU, through the use of a standardized agreement provision
                    approved by executing organizations of the EU, or taking proper, safe and secure measures.</p>
                <h3>9. Third-party sites and services</h3>
                <p>The website, products or services of Samsung may include links to third-party sites where the privacy
                    protection policy may be different. Thus, it is required that users check the policies of any
                    third-party site linked to by Samsung.</p>
                <h3>10. Contact information of the Company</h3>
                <p>Should you have any queries in respect to this policy or wish to update your information, please
                    contact Samsung using one of the following methods:<br/>
                    Samsung Electronics Co. Ltd.<br/>
                    129 Samsung-Ro, Yeongtong-Gu, Suwon-Si, Gyeonggi-Do, Republic of Korea
                </p>
                <h3>Contact</h3>
                <p>If you have any questions regarding this policy, please contact us at:
                    ssplus@samsung.com<br/>
                    The latest update date: April 22nd, 2024.</p>

            </TermContent>
        </TermsModal>
    )
}