import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSSOStore from "./stores/ssoStore";

const PrivateRoute = ({ children }) => {
    const user = useSSOStore(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("user.id", user.id)
        if (user.id === '') {
            navigate("/");
        }
    }, [user, navigate]);

    return children;
};

export default PrivateRoute;