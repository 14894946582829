import styled from "styled-components";

export const GameStep3PageWrapper = styled.div`
    align-self: flex-start;
    background: linear-gradient(0deg, #AACBFF -12.86%, rgba(170, 203, 255, 0) 69.88%);
    min-height: 100vh;
    min-height: 100svh;
    margin-top: -80px;
    display: flex;
`;
