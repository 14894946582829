import styled from "styled-components";
import {Gutters, Layout, WebFonts} from "../../styles/GlobalStyle";
import IconBack from "../../assets/images/icons/back.svg";
import {StyledButton} from "../../styles/StyledButton";
import {useEffect, useRef, useState} from "react";
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
`;

const ModalHeader = styled.header`
    display: flex;
    background: #fff;
    height: 80px;
    min-height: 80px;
    align-items: center;
    padding: 0 ${Gutters.sm};
    gap: 12px;
    h1 {
        font-family: ${WebFonts.SamsungSharpSans};
        font-weight: 700;
    }
`;

const ModalBody = styled.div`
    flex: 1;
    background: #fff;
    padding: ${Gutters.md};
    padding-top: 0;
    padding-bottom: 130px;
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 20px;
    overflow: auto;
    color: rgba(32, 32, 32, 0.6);
    scroll-behavior: smooth;
`;

const ButtonBack = styled.button`
    width: 24px;
    height: 24px;
    display: block;
    text-indent: -9999px;
    overflow: hidden;
    background: url(${IconBack}) 50% 50% no-repeat;
`;

const ModalFooter = styled.footer`
    position: fixed;
    bottom: ${Gutters.xs};
    left: 0;
    right: 0;
    z-index: 9;
    padding: ${Gutters.md};
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
    button {
        width: 100%;
    }
`;

export  const TermContent = styled.div`
    line-height: 1.4;
    p {
        font-size: 12px;
        margin-bottom: 1em;
        &+h3 {
            margin-top: 1.5em;
        }
    }
    
    ol,
    ul {
        margin-left: 1em;
        li {
            margin-bottom: .25em;
        }
        &+h3 {
            margin-top: 1.5em;  
        }
    }
    h3 {
        
        font-size: 13px;
        margin-bottom: .4em;
    }
    h4 {
        font-size: 11px;
        margin-bottom: .4em;
    }
    
    table {
        border-collapse: collapse;
        font-size: 11px;
        margin: 1em 0;
        th,
        td {
            border: 1px solid #ddd;
            padding: .325em;
            text-align: left;
        }
        th {
            background: #f1f1f1;
        }
    
    }
    .__well {
        background: #f1f1f1;
        padding: .75em;
        &+h3 {
            margin-top: 1.5em;
        }
    }
`;
export default function TermsModal({backHandler, title, children}) {
    const [ctaLabel, setCtaLabel] = useState("Scroll");
    const modalBodyRef = useRef(null);

    const checkScrolled = () => {
        return modalBodyRef.current.scrollTop + modalBodyRef.current.clientHeight >= modalBodyRef.current.scrollHeight - 10;
    }

    const ctaClickHandler = () => {
        if (checkScrolled()) {
            backHandler(true);
        } else {
            modalBodyRef.current.scrollTop += 100;
        }
    }

    const onScrollHandler = () => {
        if (checkScrolled()) {
            setCtaLabel("Agree and Continue");
        } else {
            setCtaLabel("Scroll");
        }
    }

    useEffect(() => {
        onScrollHandler();
    }, []);

    return (
        <ModalWrapper>
            <ModalHeader>
                <ButtonBack onClick={e=>backHandler(false)}>BACK</ButtonBack>
                <h1>{title}</h1>
            </ModalHeader>
            <ModalBody ref={modalBodyRef} onScroll={e=> onScrollHandler()}>
                {children}
            </ModalBody>
            <ModalFooter>
                <StyledButton onClick={ctaClickHandler}>{ctaLabel}</StyledButton>
            </ModalFooter>
        </ModalWrapper>
    )
}