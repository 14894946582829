import MessageModal from "../../components/modal/MessageModal";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import YouTube from 'react-youtube';
import QuizVideo from "../../components/step/QuizVideo";
import useGameStore from "../../stores/gameStore";
import {GameStep1PageWrapper, QuizAction, QuizButton, QuizMain, QuizTitle, TestButton} from "../../styles/Step1";
import {Trans, useTranslation} from "react-i18next";
import {insertLuckydrawMission, insertLuckydrawStamp} from "../../api/game";
import {getStampIdFromGamePeriod} from "../../states/gameState";
import useSSOStore from "../../stores/ssoStore";
import {__IS_TEST_SERVER__} from "../../stores/homeStore";


export default function Step1Page() {
    const navigate = useNavigate();
    const [showQuitModal, setShowQuitModal] = useState(false);
    const [showContinueModal, setShowContinueModal] = useState(false);

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [player, setPlayer] = useState(null);
    const [remainingTime, setRemainingTime] = useState('00:00');

    const gameStep = useGameStore(state => state.gameStep);
    const setGameStep = useGameStore(state => state.setGameStep);
    const gameInfo = useGameStore(state => state.gameInfo);
    const user = useSSOStore(state => state.user);

    function continueHandler() {
        setShowContinueModal(false);
        playVideo();
        // goEndVideo();
    }

    function stopHandler() {
        setShowContinueModal(false);
        // stopVideo();
        // goEndVideo();
    }
    function goHomeHandler() {
        navigate('/home');
    }

    function hideQuitModalHandler() {
        setShowQuitModal(false);
    }

    function checkQuizHandler() {
        if (isVideoEnded === true) {
            navigate('/game/step2');
            insertLuckydrawMission(user, getStampIdFromGamePeriod(gameInfo.gamePeriod), 1).then((result) => {
                console.log(result);
            });
        } else {
            setShowContinueModal(true);
            pauseVideo();
        }
    }


    function togglePlayVideo(e) {
        e.preventDefault();
        if(player) {
            const state = player.getPlayerState();
            if (state === YouTube.PlayerState.PLAYING) {
                pauseVideo();
            } else {
                playVideo();
            }
        }
    }

    function pauseVideo() {
        if(player) {
            player.pauseVideo();
            setIsVideoPlaying(false);
        }

    }

    function playVideo() {
        if (player) {
            player.playVideo();
            setIsVideoPlaying(true);
        }
    }

    function stopVideo() {
        if (player) {
            player.stopVideo();
            setIsVideoPlaying(false);
        }
    }

    function goEndVideo() {
        if (player) {
            player.seekTo(player.getDuration());
            setProgress(100);
            setRemainingTime('00:00');
            setIsVideoEnded(true);
        }
    }


    useEffect(() => {
        setGameStep(1);
    }, []);


    const {t} = useTranslation();
    const step1Title = t(`quiz-${gameInfo.gamePeriod}:specific_feature`);
    return (
        <GameStep1PageWrapper>

            <QuizMain>
                <QuizTitle onClick={ () =>{
                     if (__IS_TEST_SERVER__) {
                         player.seekTo(player.getCurrentTime() + 20);
                     }
                }}><span dangerouslySetInnerHTML={{__html:step1Title}}></span></QuizTitle>

                    <QuizVideo
                        isVideoPlaying={isVideoPlaying}
                        setRemainingTime={setRemainingTime}
                        player={player}
                        setPlayer={setPlayer}
                        setIsVideoEnded={setIsVideoEnded}
                        setProgress={setProgress}
                        togglePlayVideo={togglePlayVideo}
                    />
            </QuizMain>

            <QuizAction data-inited={player ? 'yes' : 'no'}>
                <p><span className="remain">{remainingTime}</span> <Trans i18nKey="game:time">remaining until next level</Trans> </p>
                <QuizButton onClick={checkQuizHandler}>
                    <i className="bar" style={{width:`${progress}%`}}></i>
                    <span><Trans i18nKey="game:go_to_quiz">Go to the Quiz</Trans></span></QuizButton>
            </QuizAction>


            {showContinueModal && <MessageModal msg={t('game:video_leave_msg')} btns={[{label:t('game:continue'), action: continueHandler}, {label: t('game:stop'), action: stopHandler}]}/>}

            {showQuitModal && <MessageModal msg={t('game:leave_msg')} btns={[{label:t('game:leave'), action: goHomeHandler}, {label: t('game:stay'), action: hideQuitModalHandler}]}/>}

            <TestButton onClick={()=>{
                player.seekTo(player.getCurrentTime() + 20);
            }}>TEST</TestButton>
        </GameStep1PageWrapper>
    )
}