import axios from 'axios';
import {API_BASE_URL, SSO_API_BASE_URL, SSO_REDIRECT_URI} from "./config";

export function getUserInfo($code, controller) {

    const config = {
        redirect_uri: SSO_REDIRECT_URI,
        code: $code
    }
    if (controller) {
        config.signal = controller.signal;
    }
    return axios.post(`${API_BASE_URL}/sso/getUserInfo`, config).then(res => {
        if (res.data.access_token  === '' || res.data.access_token === null || res.data.access_token === undefined) {
            window.location.href = '/';
        }
        if (res && res.data && res.data.user) return res.data.user;
        return null;
    }).catch(err => console.error(err.message));
}