import {HomeContainer, HomeSectionTitle} from "../../styles/HomeStyle";
import styled from "styled-components";
import {BorderRadius, Colors, Gutters, WebFonts} from "../../styles/GlobalStyle";
import ImgNoteObj from "../../assets/images/home/note-obj.svg";
import StampItem from "./StampItem";
import ssoStore from "../../stores/ssoStore";
import {useSectionPos} from "../../hooks/useSectionPos";
import ImgLogoStamp from "../../assets/images/home/logo-stamp.png";
import {useTranslation,Trans} from "react-i18next";
import useGameStore, {END_DATE} from "../../stores/gameStore";
import {GAME_PERIOD_1ST, GAME_PERIOD_2ND, GAME_PERIOD_3RD, GAME_PERIOD_4TH} from "../../states/gameState";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import {StyledButton} from "../../styles/StyledButton";
import {useNavigate} from "react-router-dom";

const StampMissionWrapper = styled.section`
    margin: 0 0 40px 0;
    position: relative;
    &:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        height: 100px;
        top: 100%;
        background: linear-gradient(180deg, #F4DCFF 20%,  #F4F4F4 100%);
    }
`;

const Note = styled.div`
    box-sizing: border-box;
    position: relative;
    &:before {
        content: '';
        position: absolute; 
        inset: -10px;
        background: #E2E7EF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 16px 16px 10px rgba(0, 0, 0, 0.1);
        border-radius: 35px;
    }
`;

const NoteInner = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 26px;
    position: relative;
    padding: 40px 0 30px;
    
`;


const NoteTop = styled.div`
    position: absolute;
    left: 10px;
    right: 10px;
    top: -22px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
        display: block;
    }
`;

const StampMissionContent = styled.div`
    padding: 0 ${Gutters.default};
    max-width: 360px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
`;

const StampInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 14px 20px;
    gap: 10px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 20px;

    p {
        display: flex;
        align-items: center;
        gap: 4px;
        line-height: 1;
        color: #666;
        
        
        strong {
            color: ${Colors.Purple};
            font-size: 16px;
            font-weight: 700;
            font-family: ${WebFonts.SamsungSharpSans};
            margin-top: 3px;
        }
    }
`;

const Stamps = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
`;


const NoticeWrapper = styled.div`
    padding: ${Gutters.default};
    padding-bottom: 40px;
    background: linear-gradient(180deg, #F4F4F4 20%,  #AACBFF 100%);
`;

const Notice = styled.div`
    background: #fff;
    border-radius: ${BorderRadius.xl};
    padding: ${Gutters.sm};
    font-family: ${WebFonts.SamsungSharpSans};
    .swiper-pagination {
        position: relative;
        margin: 20px auto -10px;
        --swiper-pagination-color: ${Colors.Black};   
    }
`;

const LuckyDrawInfo = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    h3 {
        font-size: 20px;
        font-family: ${WebFonts.SamsungSharpSans};
        color: ${Colors.Purple};
        margin-bottom: 10px;
    }
    
    p {
        font-family: ${WebFonts.SamsungOne};
        font-size: 14px;
        margin-bottom: 14px;
        font-weight: 700;
        small {
            font-weight: 400;
            display: inline-block;
            margin-top: 8px;
            font-size: 13px;
            color: #565656;
        }
    }
`;

const NoticeInfo = styled.div`
    div {
        display: grid;
        align-items: center;
        grid-template-columns: 76px 1fr;
    }
    img {
        display: block;
        width: 66px;
        min-width: 66px;
    }
    h3 {
        font-weight: 700;
        font-size: 16px;
        padding: 0 10px;
    }

    p {
        margin-top: ${Gutters.sm};
        background: #F3ECFF;
        color: ${Colors.Purple};
        font-size: 14px;
        font-weight: 700;
        padding: 6px 10px;
        border-radius: 20px;
        text-align: center;
    }
`;


const NoticeDesc = styled.p`
    margin-top: 14px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #939393;
`;

const StampWarning = styled.div`
    font-size: 12px;
    line-height: 1.5;
    opacity: .5;
    margin-top: 20px;
    text-align: center;
`;


export default function HomeStampMission() {
    const user = ssoStore(state => state.user);
    const {section} = useSectionPos();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const gameInfo = useGameStore(state => state.gameInfo);

    const getGameIndex = (period) => {
        switch (period) {
            case GAME_PERIOD_1ST:
                return 1;
            case GAME_PERIOD_2ND:
                return 2;
            case GAME_PERIOD_3RD:
                return 3;
            case GAME_PERIOD_4TH:
                return 4;
            default:
                return 1;
        }
    }
    const gameIndex = getGameIndex(gameInfo.gamePeriod);
    const endTime = new Date(END_DATE).getTime();
    const currentTime = new Date().getTime();
    const timeRemains =
        (sessionStorage.getItem('gamePeriod') && sessionStorage.getItem('gamePeriod') !== '')  ? 1000 : endTime - currentTime;

    const getStatus = (index) => {
        if ( timeRemains <= 0) return 'closed';
        if (gameIndex === index) {
            return 'open';
        } else if (gameIndex > index) {
            return 'closed';
        } else {
            return 'locked';
        }
    }

    const getCompleted = (index) => {
        const stampUserData = gameInfo.stampUserData;
        if (!stampUserData) return 'no';
        const mission1Check = stampUserData[`mission${index}_1`] === 'Y';
        const mission2Check = stampUserData[`mission${index}_2`] === 'Y';
        const mission3Check = stampUserData[`mission${index}_3`] === 'Y';
        if (mission1Check && mission2Check && mission3Check) {
            return 'yes';
        }
        return 'no';

    }
    return (
        <HomeContainer>
            <StampMissionWrapper className="home-section" id="stampMission" ref={section}>
                <NoticeWrapper>
                    {timeRemains <= 0 ? (
                            <Notice>
                                <LuckyDrawInfo>
                                    <h3><Trans i18nKey="luckydraw:winners_chosen">Winners have been chosen</Trans></h3>
                                    <p>
                                        <Trans i18nKey="luckydraw:winners_chosen_msg">Tap on below button to view winners' list</Trans>
                                    </p>
                                    <StyledButton onClick={()=>{
                                        navigate('/luckydraw');
                                    }}><Trans i18nKey="luckydraw:winners_result_cta">Find out the winners</Trans></StyledButton>
                                </LuckyDrawInfo>
                            </Notice>
                    ) : (
                        <Notice>
                            { gameIndex === 4 ? (
                                <Swiper modules={[Pagination, Autoplay]}
                                        autoplay={{
                                            delay: 10000,
                                        }}
                                        pagination={{ clickable: true }}>
                                    <SwiperSlide>
                                        <LuckyDrawInfo>
                                            <h3><Trans i18nKey="home:banner_lucky_draw_open">Lucky Draw Open!</Trans></h3>
                                            <p>
                                                <Trans i18nKey="home:notice_event_end">The event ends this Sunday at 23:59</Trans>
                                                <br/>
                                                <small>* <Trans i18nKey="home:lucky_draw_entry_period" values={{period:"2024. 7. 8 - 7. 14"}}>Lucky draw entry period : 2024. 7. 8 - 7. 14</Trans> *</small>
                                            </p>
                                            <StyledButton onClick={()=>{
                                                navigate('/luckydraw');
                                            }}><Trans i18nKey="home:lucky_draw_cta_label">Go to Lucky Draw</Trans></StyledButton>
                                        </LuckyDrawInfo>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <NoticeInfo>
                                            <div>
                                                <img src={ImgLogoStamp} alt="Movistar"/>
                                                <h3><Trans i18nKey="home:stamp_mission_slogan"/></h3>
                                            </div>
                                            <p>From 17th June</p>
                                        </NoticeInfo>
                                        <NoticeDesc>
                                            <Trans i18nKey="home:notice1">Let’s play game, learn sales skill and earn prizes from Samsung</Trans>
                                        </NoticeDesc>
                                    </SwiperSlide>
                                </Swiper>
                            ) : (
                                <>
                                    <NoticeInfo>
                                        <div>
                                            <img src={ImgLogoStamp} alt="Movistar"/>
                                            <h3><Trans i18nKey="home:stamp_mission_slogan"/></h3>
                                        </div>
                                        <p>From 17th June</p>
                                    </NoticeInfo>
                                    <NoticeDesc>
                                        <Trans i18nKey="home:notice1">Let’s play game, learn sales skill and earn prizes from Samsung</Trans>
                                    </NoticeDesc>
                                </>
                            )}
                        </Notice>
                    )}

                </NoticeWrapper>
                <Note>
                    <NoteInner>
                        <NoteTop>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                            <img src={ImgNoteObj}/>
                        </NoteTop>
                        <HomeSectionTitle><Trans i18nKey="stamp_mission_title">Stamp Mission</Trans></HomeSectionTitle>
                        <StampMissionContent>
                            <StampInfo>
                                <h4>{user.fullName}</h4>
                                <p><Trans i18nKey="home:total_stamp" values={{count:gameInfo.stampCount}}></Trans></p>
                            </StampInfo>
                            <Stamps>
                                <StampItem no="01" date="6/17" status={getStatus(1)} completed={getCompleted(1)}/>
                                <StampItem no="02" date="6/24" status={getStatus(2)} completed={getCompleted(2)}/>
                                <StampItem no="03" date="7/1" status={getStatus(3)} completed={getCompleted(3)}/>
                                <StampItem no="04" date="7/8" status={getStatus(4)} completed={getCompleted(4)}/>
                            </Stamps>
                            <StampWarning>* {t('home:stamp_mission_notice')} *</StampWarning>
                        </StampMissionContent>

                    </NoteInner>
                </Note>
            </StampMissionWrapper>
        </HomeContainer>
    )
}