import YouTube from "react-youtube";
import {useEffect} from "react";
import styled from "styled-components";
import {Gutters} from "../../styles/GlobalStyle";
import ImgIconPlay from "../../assets/images/icons/play.svg";
import useGameStore from "../../stores/gameStore";
import {getElearningVideoId} from "../../states/gameState";

const QuizVideoWrapper = styled.div`
    
`;

const QuizVideoPlayer = styled.div`
    width: 100%;
    aspect-ratio: 16/9;
    background: #999;
    position: relative;
    .player {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const QuizHint = styled.p`
    font-size: 14px;
    font-weight: 500;
    padding: ${Gutters.xs} ${Gutters.sm};
    color: #5F5F5F;
`;

const VideoPlayButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0,0,0,.5) url(${ImgIconPlay}) 50% 50% no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    
    &.playing {
        opacity: 0;
    }
`

export default function QuizVideo({isVideoPlaying, setRemainingTime, player, setPlayer, setIsVideoEnded, setProgress, togglePlayVideo}) {

    const {state} = useGameStore();
    const handleReady = (event) => {
        setPlayer(event.target);
    };
    const handleStateChange = (event) => {
        if (event.data === YouTube.PlayerState.ENDED) {
            setProgress(100);
            setRemainingTime('00:00');
            setIsVideoEnded(true);
        }
    };

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            playsinline: 1,
            controls: 0,
            cc_lang_pref: 'vi',
            cc_load_policy: 1,
            hl: 'vi',
        },
    };


    useEffect(() => {
        const updateProgress = () => {
            if (player && player.getCurrentTime) {
                const duration = player.getDuration();
                const currentTime = player.getCurrentTime();
                const progress = (currentTime / duration) * 100;
                setProgress(progress);
            }
        }
        const interval = setInterval(updateProgress, 200);
        updateProgress();
        return () => {
            clearInterval(interval);
        };
    }, [player]);

    useEffect(() => {
        const updateRemainTime = () => {
            if (player && player.getCurrentTime) {
                const duration = player.getDuration();
                const currentTime = player.getCurrentTime();
                const remaining = duration - currentTime;

                const minutes = Math.floor(remaining / 60);
                const seconds = Math.floor(remaining % 60);

                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');

                setRemainingTime(`${formattedMinutes}:${formattedSeconds}`);
            }
        }
        const interval = setInterval(updateRemainTime, 1000);
        updateRemainTime()
        return () => {
            clearInterval(interval);
        };
    }, [player]);

    const gameInfo = useGameStore(state => state.gameInfo);
    const videoId = getElearningVideoId(gameInfo.gamePeriod);
    return (
        <QuizVideoWrapper>
            <QuizVideoPlayer>
                <YouTube className="player" videoId={videoId} opts={opts}
                         onReady={handleReady}
                         onStateChange={handleStateChange}/>
                <VideoPlayButton className={isVideoPlaying ? 'playing': 'paused'} onClick={togglePlayVideo}/>
            </QuizVideoPlayer>
            {/*<QuizHint>{state.step1.hint}</QuizHint>*/}
        </QuizVideoWrapper>
    )
}