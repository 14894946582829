import styled from "styled-components";
import ImgIconX from "../assets/images/icons/x.svg";
import Footer from "../components/common/Footer";
import {useNavigate} from "react-router-dom";
import {Gutters} from "../styles/GlobalStyle";
import LuckyDrawHeader from "../components/luckydraw/Header";
import LuckyDrawMain from "../components/luckydraw/Main";
import {useEffect, useLayoutEffect, useState} from "react";
import {getLuckydrawStampInfo} from "../api/game";
import {getLuckydrawInfo} from "../api/luckydraw";
import useSSOStore from "../stores/ssoStore";
import useGameStore from "../stores/gameStore";
import History from "../components/luckydraw/partial/History";
const LuckyDrawPageWrapper = styled.div`
`;

const LuckyDrawContent = styled.div`
    background: linear-gradient(0deg, #F4DCFF 0%, #AACBFF 48.97%, rgba(170, 203, 255, 0) 86.02%);
    min-height: 50svh;
    padding: 56px ${Gutters.default};
`;

const ButtonClose = styled.button`
    position: fixed;
    top: ${Gutters.sm};
    right: ${Gutters.default};
    z-index: 99;
    display: block;
    img {
        display: block;
    }
`;

export default function LuckyDrawPage() {
    const user = useSSOStore(state => state.user);
    const setPriceCounts = useGameStore(state => state.setPrizeCounts);
    const gameInfo = useGameStore(state => state.gameInfo);
    const setLuckydrawHistory = useGameStore(state => state.setLuckydrawHistory);
    const setEnddate = useGameStore(state => state.setEnddate);
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/home');
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        getLuckydrawInfo(user).then((info) => {
            if (info && info.counts) {
                setPriceCounts(info.counts);
            }
            if (info && info.userdata) {
                gameInfo.stampUserData = info.userdata;
            }
            if (info && info.history) {
                setLuckydrawHistory(info.history);
            }
            if (info && info.enddate) {
                setEnddate(info.enddate);
            }
        });
    }, []);
    return (
        <LuckyDrawPageWrapper>
            <ButtonClose onClick={goHome}><img src={ImgIconX} alt="X"/></ButtonClose>
            <LuckyDrawContent>
                <LuckyDrawHeader/>
                <LuckyDrawMain />
            </LuckyDrawContent>
            <Footer/>
        </LuckyDrawPageWrapper>
    )
}