import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {SSO_AUTHORIZE_URL, SSO_CLIENT_ID, SSO_REDIRECT_URI} from "../api/config";
import styled from "styled-components";
import {Colors, WebFonts} from "../styles/GlobalStyle";
import {__IS_TEST_SERVER__} from "../stores/homeStore";

const SplashPageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${Colors.Gray1};
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    h1 {
        font-family: ${WebFonts.SamsungSharpSans};
        font-size: 20px;
        font-weight: 700;
        strong {
            color: ${Colors.Purple};
            font-weight: 700;
        }
    }
`

export default function SplashPage() {


    useEffect(() => {
        if (__IS_TEST_SERVER__) {
            if (window.location.hash) {
                window.sessionStorage.setItem('gamePeriod', window.location.hash.split('#')[1]);
            } else {
                window.sessionStorage.removeItem('gamePeriod');
            }
        }
        window.location.href = `${SSO_AUTHORIZE_URL}?client_id=${SSO_CLIENT_ID}&redirect_uri=${SSO_REDIRECT_URI}&response_type=code&scope=allapis`;
    }, []);


    return (
        <SplashPageWrapper>
            <h1><strong>Lucky Draw</strong><br/>
                Samsung+<br/>
                Weekly Stamp Mission</h1>
        </SplashPageWrapper>
    )
}