import styled from "styled-components";
import {WebFonts} from "./GlobalStyle";

export const SubPageWrapper = styled.div`
    background: #F4F4F4;
    min-height: 100svh;
    display: grid;
    grid-template-rows: auto 1fr;
`;

export const SubPageHeader = styled.header`
    background: #fff;
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 12px;
    .x {
        display: block;
        svg {
            width: 24px;
            height: 24px;
        }
    }
    h1 {
        font-family: ${WebFonts.SamsungSharpSans};
        font-size: 18px;
        font-weight: 600;
    }
`;

export const SubPageBody = styled.main`
    padding: 30px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    p {
        margin-bottom: 1em;
    }
   
    .__result-block {
        margin-bottom: 2em;
        h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: .8em;
        }
        
        ul {
            list-style: none;
            list-style-position: inside;
            padding: 0;
            margin: 0;
            
            &.__2 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
`;