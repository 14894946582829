import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

export const DEFAULT_LANG =  'vi'; //'en'; // navigator.languages ? navigator.languages[0] : navigator.language;

i18n
    .use(initReactI18next)
    .use(HttpBackend)
    .init({
        lng: DEFAULT_LANG,
        fallbackLng: "en",
        ns: [
            "home",
            "intro",
            "privacy-policy",
            "terms-and-conditions",
            "consideration",
            "game",
            "luckydraw",
            "quiz-1st",
            "quiz-2nd",
            "quiz-3rd",
            "quiz-4th",
        ],
        defaultNS: "home",
        backend: {
            // loadPath: '/locales/{{lng}}/{{ns}}.json',
            loadPath: '/locales/vi/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        useSuspense: true,
    });

i18n.on('languageChanged', function(lng) {
    document.documentElement.lang = lng;
});
export default i18n;