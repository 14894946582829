import styled from "styled-components";
import {useState} from "react";
import TimeRemains from "./TimeRemains";
import TotalStamps from "./TotalStamps";
import MessageModal from "../modal/MessageModal";
import Drawer from "./Drawer";
import ssoStore from "../../stores/ssoStore";
import useGameStore, {END_DATE} from "../../stores/gameStore";

const LuckyDrawMainWrapper = styled.section`
    margin-top: 30px;
`;


export default function LuckyDrawMain() {

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    }

    const user = ssoStore(state => state.user);
    const gameInfo = useGameStore(state => state.gameInfo);
    return (
        <LuckyDrawMainWrapper>
            <TimeRemains endDate={END_DATE}/>
            <TotalStamps remains={gameInfo?.stampUserData?.stamp}/>
            <Drawer endDate={END_DATE}/>
            { showModal && <MessageModal msg={`You need 1 stamp to enter the draw<br/> (No stamps remaining) `} isInfo={true} btns={[{label:'OK', action: closeModal}]}/>}
        </LuckyDrawMainWrapper>
    )
}