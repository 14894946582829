import styled from "styled-components";
import {Colors, Gutters, Layout, WebFonts} from "../../styles/GlobalStyle";
import {HomeContainer} from "../../styles/HomeStyle";
import useHomeStore from "../../stores/homeStore";
import {useCallback, useEffect} from "react";
import {useTranslation, Trans} from "react-i18next";

const HomeMainTitle = styled.h1`
    font-family: ${WebFonts.SamsungSharpSans};
    font-size: 16px;
    font-weight: 700;
    padding: 26px ${Gutters.default};
    text-align: center;
    strong {
        color: ${Colors.Purple};
        font-weight: 700;
        font-size: 30px;
    }
`;

const HomeNav = styled.nav`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    position: sticky;
    top: 0;
    padding: ${Gutters.xs} ${Gutters.default};
    z-index: 99;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    width: 100%;
    max-width: ${Layout.maxWidth};
`;

const HomeNavItem = styled.div`
    flex: 1;
    display: flex;
    a {
        align-items: center;
        justify-content: center;
        display: flex;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        background-color: ${Colors.PurpleLight};
        border-radius: 30px;
        height: 40px;
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        padding: 0 1em;
        transition: background-color 200ms;
        -webkit-tap-highlight-color: transparent;
        span {
            opacity: .3;
        }
        &.active {
            background-color: ${Colors.Purple};
            span {
                opacity: 1;
                color: #fff;
            }
        }
    }
`;

export const TOP_DIFF = 60;

export default function HomeHeader() {

    const currentSection = useHomeStore(state => state.currentSection);

    const navItemClickHandler = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        const target = e.currentTarget;
        const targetId = target.getAttribute('href');
        const targetEl = document.querySelector(targetId);
        if (!targetEl) return;
        const targetElOffsetTop = targetEl.offsetTop - TOP_DIFF;
        window.scrollTo({
            top: targetElOffsetTop,
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        console.log("state.currentSection", currentSection)
    }, [currentSection]);


    return (
        <>
            <HomeContainer>
                <HomeMainTitle><strong><Trans i18nKey="home:main_title">Lucky Draw</Trans></strong><br/>
                    <Trans i18nKey="home:main_sub_title">Samsung+ Weekly Stamp Mission</Trans></HomeMainTitle>
            </HomeContainer>
            <HomeNav>
                <HomeNavItem>
                    <a href="#stampMission" onClick={navItemClickHandler} className={currentSection === 'stampMission' ? 'active' : ''}><span><Trans i18nKey="home:stamp_mission">Stamp<br/> Mission</Trans></span></a>
                </HomeNavItem>
                <HomeNavItem>
                    <a href="#luckyDraw" onClick={navItemClickHandler} className={currentSection === 'luckyDraw' ? 'active' : ''}><span><Trans i18nKey="home:lucky_draw_prizes">Lucky Draw<br/> Prizes</Trans></span></a>
                </HomeNavItem>
                <HomeNavItem>
                    <a href="#howToEnter" onClick={navItemClickHandler} className={currentSection === 'howToEnter' ? 'active' : ''}><span><Trans i18nKey="home:how_to_enter">How to<br/> Enter</Trans></span></a>
                </HomeNavItem>
            </HomeNav>
        </>
    )
}