import {useNavigate} from "react-router-dom";
import useGameStore from "../../stores/gameStore";
import {useEffect} from "react";
import {GameStep3PageWrapper} from "../../styles/Step3";
import PuzzleGame from "../game/PuzzleGame";
import SpotTheDifference from "../game/SpotTheDifference";
import {GAME_PERIOD_1ST, GAME_PERIOD_3RD} from "../../states/gameState";

export default function Step3Page() {

    const navigate = useNavigate();
    const setGameStep = useGameStore(state => state.setGameStep);
    const gameInfo = useGameStore(state => state.gameInfo);
    useEffect(() => {
        setGameStep(3);
    }, []);

    return (
        <GameStep3PageWrapper>
            { gameInfo.gamePeriod === GAME_PERIOD_1ST || gameInfo.gamePeriod === GAME_PERIOD_3RD ? <PuzzleGame/> : <SpotTheDifference/> }
        </GameStep3PageWrapper>
    )
}