import {useEffect, useRef} from "react";
import useHomeStore from "../stores/homeStore";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {TOP_DIFF} from "../components/home/Header";

export const useSectionPos = () => {
    const section = useRef();
    const setCurrentSection = useHomeStore(state => state.setCurrentSection);
    useEffect(() => {
        const sectionId = section.current.id;
        ScrollTrigger.create({
            trigger: section.current,
            start: `top ${TOP_DIFF+4}px`,
            end: `bottom ${TOP_DIFF+3}px`,
            onToggle: ({isActive}) => {
                if (isActive) {
                    setCurrentSection(sectionId);
                }
            }
        });
    }, [section]);
    return {section};
}