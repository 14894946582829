import {Colors, Gutters, WebFonts} from "./GlobalStyle";
import styled from "styled-components";

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-family: ${WebFonts.SamsungSharpSans};
    background-color: ${Colors.Purple};
    color: ${Colors.White};
    font-size: 16px;
    font-weight: 700;
    border-radius: 40px;
    padding: 0 ${Gutters.md};
    box-sizing: border-box;
    &[disabled],
    &.disabled {
        background-color: ${Colors.Gray2};
    }
`;