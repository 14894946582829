import styled from "styled-components";
import {Gutters} from "../../styles/GlobalStyle";
import {HomeContainer} from "../../styles/HomeStyle";
import {useTranslation} from "react-i18next";

const FooterWrapper = styled.footer`
`;

const Consideration = styled.div`
    padding: ${Gutters.lg} ${Gutters.md};
    color: #767676;
    h3 {
        font-size: 12px;
        margin-bottom: 10px;
    }
    
    ol {
        font-size: 11px;
        margin-left: 1em;
    }
`;

const Copy = styled.div`
    color: #989898;
    background: #434343;
    padding:  ${Gutters.default};
    font-size: 12px;
    text-align: center;
    span {
        display: inline-block;
    }
`;
export default function Footer() {
    const {t} = useTranslation();
    return (
        <FooterWrapper>
            <HomeContainer>
                <Consideration>
                    <h3>{t('consideration:title')}</h3>
                    <ol>
                        <li>{t('consideration:notice1')}</li>
                        <li>{t('consideration:notice2')}</li>
                        <li>{t('consideration:notice3')}</li>
                        <li>{t('consideration:notice4')}</li>
                        <li>{t('consideration:notice5')}</li>
                        <li>{t('consideration:notice6')}</li>
                        <li>{t('consideration:notice7')}</li>
                        <li>{t('consideration:notice8')}</li>
                        <li>{t('consideration:notice9')}</li>
                        <li>{t('consideration:notice10')}</li>
                        <li>{t('consideration:notice11')}</li>
                        <li>{t('consideration:notice12')}</li>
                        <li>{t('consideration:notice13')}</li>
                        <li>{t('consideration:notice14')}</li>
                        <li>{t('consideration:notice15')}</li>
                        <li>{t('consideration:notice16')}</li>
                        <li>{t('consideration:notice17')}</li>
                    </ol>
                </Consideration>
            </HomeContainer>
            <Copy>
                <HomeContainer>
                    <p>
                        <span>Copyright © 1995-2024 Samsung.</span>
                        <span>All Rights Reserved.</span>
                    </p>
                </HomeContainer>
            </Copy>
        </FooterWrapper>
    )
}