import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import Step1Page from "./step/Step1Page";
import Step2Page from "./step/Step2Page";
import Step3Page from "./step/Step3Page";
import {useEffect} from "react";
import styled from "styled-components";
import {Colors, Layout} from "../styles/GlobalStyle";
import GamePageHeader from "../components/step/Header";


const GamePageWrapper = styled.div`
    margin: 0 auto;
    max-width: ${Layout.maxWidth};
    background: ${Colors.Gray1};
    min-height: 100vh;
    min-height: 100svh;
    height: 100svh;
    display: flex;
    flex-direction: column;
`;

const GamePageBody = styled.div`
    flex: 1;
    display: grid;
`;
export default function GamePage() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/game') navigate('/game/step1');
    }, [navigate]);
    return (
        <GamePageWrapper>
            <GamePageHeader />
            <GamePageBody>
                <Routes>
                    <Route path="step1" element={<Step1Page />}/>
                    <Route path="step2" element={<Step2Page />}/>
                    <Route path="step3" element={<Step3Page />}/>
                </Routes>
            </GamePageBody>
        </GamePageWrapper>
    )
}